import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import moment from "moment";
import axios from "../../../config/axios";

// importing components
import Layout from "../../../Components/Layout";
import { Button, message, Switch, Tooltip, Pagination, Spin } from "antd";
import SimpleLoader from "../../../Components/SimpleLoader/SimpleLoader";
import NoData from "../../../Components/Library/NoData";

// importing styles
import styles from "./AllBlogs.module.scss";

// importing icons
import { PlusOutlined } from '@ant-design/icons';
import { FiEdit } from "react-icons/fi";


const AllBlogs = () => {
    const [loading, setLoading] = useState(true);
    const [spin, setSpin] = useState(false);
    const [allBlogs, setAllBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [blogCount, setBlogCount] = useState(0);

    useEffect(() => {
        const getAllBlogs = async () => {
            setSpin(true)
            const res = await axios.get("/api/blogs", {
                withCredentials: true, params: {
                    page: currentPage,
                    limit: limit,
                }
            })
            setBlogCount(res.data.count)
            setAllBlogs(res.data.results)
            setSpin(false)
            setLoading(false)
        }
        getAllBlogs()
    }, [currentPage, limit]);

    const blogVisibilityChangeHandler = async (id, value) => {
        await axios.put(`/api/blogs/mod/${id}`, { is_visible: value }, { withCredentials: true })
        message.success("Blog Visibility Updated Successfully!");
    }

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Blog`;
    };

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    return (
        <Layout pageName="All Blogs">
            <div className="card">
                <div className={styles.blog_top}>
                    <h2 className="primary_h2"> All Blogs</h2>
                    <Link to="/blogs/create-blog">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Create New Blog
                        </Button>
                    </Link>
                </div>

                {loading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <>
                        {allBlogs.length > 0 ? (
                            <>
                                <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                    <table>
                                        <thead>
                                            <tr className="tr__head">
                                                <th className="th"> Published At </th>
                                                <th className="th"> Title </th>
                                                <th className="th"> Visible? </th>
                                                <th className="th"> Actions </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {allBlogs.map((blog, index) => (
                                                <tr className="tr" key={index}>

                                                    <td className="td"> {moment(blog.timezone).format('D MMMM YYYY - LT')} </td>

                                                    <td className="td"> {blog.title} </td>

                                                    <td className="td">
                                                        <Switch
                                                            defaultChecked={blog.is_visible}
                                                            onChange={(value) =>
                                                                blogVisibilityChangeHandler(blog.id, value)
                                                            }
                                                        />
                                                    </td>

                                                    <td className="td">
                                                        <Link to={`/blogs/edit-blog/${blog.id}`}>
                                                            <Tooltip title="Edit Blog" placement="top">
                                                                <FiEdit size={20} />
                                                            </Tooltip>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Spin>

                                <div className="sh__pagination__center">
                                    <Pagination
                                        showTotal={showTotal}
                                        onShowSizeChange={onShowSizeChange}
                                        size="small"
                                        total={blogCount}
                                        onChange={handleCurrentPage}
                                        pageSize={limit}
                                        current={currentPage}
                                    />
                                </div>
                            </>
                        ) : (
                            <NoData height="10vh" message="No Blogs Found" />
                        )
                        }
                    </>
                )}
            </div>
        </Layout>
    )
}

export default AllBlogs;