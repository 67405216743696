//defining checkObjectNullity function
export const checkObjectNullity = (obj) => {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
};

//defining shortNameGenerator function
export const shortNameGenerator = (fullName) => {
    let shortName = fullName.split(" ")[0].charAt(0) + fullName.split(" ")[1].charAt(0);

    return shortName;
};

//removes an item from array and returns a newly generated array
export const removeArrItem = (arr, item) => {
    var index = arr.indexOf(item);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

//defining colorByStatus function
export const colorByStatus = (status) => {
    let color;
    switch (status) {
        case "pending":
            color = "#DE970B";
            break;
        case "confirmed":
            color = "var(--color-blue)";
            break;
        case "processing":
            color = "var(--color-purple)";
            break;
        case "delivered":
            color = "var(--color-green)";
            break;
        case "cancelled":
            color = "var(--color-red)";
            break;
        case "due":
            color = "var(--color-red)";
            break;
        case "paid":
            color = "var(--color-green)";
            break;

        default:
            color = "var(--color-hotpink)";
            break;
    }

    return color;
};

//defining colorByRiderStatus function
export const colorByRiderStatus = (status) => {
    let color;
    switch (status) {
        case "engaged":
            color = "var(--color-red)";
            break;

        default:
            color = "var(--color-green)";
            break;
    }

    return color;
};

export const formattedPrice = (x) => {
    return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BDT`;
};

export const stringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
};

export const calculateGroceryProductStock = (product, zone) => {
    console.log(product, zone);
    if (product.merchant.zone == zone) {
        return product.stock;
    } else {
        let index = product.clones.findIndex(function (clone) {
            return clone.zone == zone;
        });
        if (index === -1) {
            //it means there's no cloned product for this zone
            // console.log('no cloned product')
            return 0;
        } else {
            return product.clones[index].stock;
        }
    }
};

export const getGroceryProductSku = (product, zone) => {
    // console.log(product)
    if (product.merchant.zone == zone) {
        return product.sku;
    } else {
        let index = product.clones.findIndex(function (clone) {
            return clone.zone == zone;
        });
        if (index === -1) {
            //it means there's no cloned product for this zone
            return product.sku; //TODO:Gotta check this. Returning this to be safe
        } else {
            return product.clones[index].sku;
        }
    }
};

export const zoneIdToName = (zoneID) => {
    switch (zoneID) {
        case 1:
            return "Narayanganj";
        case 2:
            return "Dhaka";
        default:
            return "Invalid Zone";
    }
};

export function hasUnicodeCharacters(str) {
    for (var i = 0, n = str.length; i < n; i++) {
        if (str.charCodeAt(i) > 255) {
            return true;
        }
    }
    return false;
}
