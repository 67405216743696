import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "../../../config/axios";
import moment from "moment";

//importing components
import { message, Switch, Tag, Tooltip, Image, Modal, Checkbox, Button, Input, Form, Spin, Select } from "antd";

//importing icon
import { FiEdit } from "react-icons/fi";


//importing utils
import { formattedPrice } from "../../../utils/helpers";
import ClickToCopyText from "../../Library/ClickToCopyText";
import { BsPlusCircleDotted } from "react-icons/bs";

const ProductItem = ({product}) => {
    const [visible, setVisible] = useState(false);
    const [is_visible, setIsVisible] = useState(product.is_visible);
    const [flashsale, setFlashsale] = useState(product.flash_sale);
    const history = useHistory();

    const [trait, setTrait] = useState(null);

    useEffect(() => {
        setTrait(product.trait);
        setIsVisible(product.is_visible);
        setFlashsale(product.flash_sale);
    }, [product]);
    const [color, setColor] = useState('');
    const [showColorVariantModal, setShowColorVariantModal] = useState(false);
    const [colorLoading, setColorLoading] = useState(false);


    const productVisibilityChangeHandler = (value) => {
        let payload = {
            is_visible: value,
        };

        setIsVisible(value);

        axios.put(`/api/products/mod/${product.id}`, payload, {withCredentials: true}).then((response) => {
            message.success("Product Visibility Updated Successfully!");
        });
    };

     const flashsaleHandler = (value) => {
        let payload = {
            flash_sale: value,
        };

        setFlashsale(value);

        axios.put(`/api/products/mod/${product.id}`, payload, {withCredentials: true}).then((response) => {
            message.success("Product Flash Sale Status Updated Successfully!");
        });
    };


    const addColorVariant = () => {
        if (!color) {
            message.error('Color is required!');
            return;
        }

        setColorLoading(true);

        axios.post(`/api/admin/products/variants/color/${product.id}`, {color}, {withCredentials: true}).then(response => {
            message.success(('Color Variant Created Successfully!'));
            history.push(`/products/edit-product/${response.data.results.new_product_id}`);
        }).catch(err => {
            message.error(err.response?.data?.msg ? err.response.data.msg : 'A server-side error occurred!');
        }).finally(()=>{
            setColorLoading(false);
        })
    }

    const handleTraitChange = value =>{
        let payload = {
            trait: value
        }

        setTrait(value);

        axios.put(`/api/products/mod/${product.id}`, payload, {withCredentials: true}).then(response=>{
            message.success('Product Attribute Updated Successfully!');
        })
    }

    return (
        <>
            <tr className="tr">
                <td className="td">
                    {/* show all images as preview and group */}
                    <Image
                        preview={{visible: false}}
                        style={{objectFit: "contain", borderRadius: "var(--radius)"}}
                        width={80}
                        height={80}
                        src={product.featured_image}
                        onClick={() => setVisible(true)}
                    />
                    <div style={{display: "none"}}>
                        <Image.PreviewGroup preview={{visible, onVisibleChange: (vis) => setVisible(vis)}}>
                            {product.images.map((image, index) => (
                                <Image key={index} src={image}/>
                            ))}
                        </Image.PreviewGroup>
                    </div>
                </td>
                <td className="td">
                    <a style={{color: "var(--color-blue)"}} href={`https://cbih.com.bd/products/${product.slug}`}
                       target="_blank" rel="noreferrer">
                        {product.name}
                    </a>
                </td>
                <td className="td">
                    <Tag>Created: {moment(product.timestamp).format("MM/DD/YY-LT")}</Tag>
                    <br/>
                    <Tag style={{marginTop: "1rem"}}>Updated: {moment(product.updated_at).format("MM/DD/YY-LT")}</Tag>
                </td>
                <td className="td">
                    <table>
                        <tr>
                            <th style={{textAlign: 'left'}}>SKU</th>
                            <th style={{textAlign: 'left'}}>Size</th>
                            <th style={{textAlign: 'left'}}>Stock</th>
                        </tr>
                        {
                            product.variants.map((variant, index) => {
                                return <tr key={index}>
                                    <td>
                                        <ClickToCopyText text={variant.sku}/>
                                    </td>
                                    <th>{variant.size}</th>
                                    <th>{variant.stock}</th>
                                </tr>
                            })
                        }
                    </table>

                </td>
                <td className="td">
                    <Tag color="black">{formattedPrice(product.regular_price)}</Tag>
                </td>
                <td className="td">
                    <Tag
                        color="red">{formattedPrice(product.discounted_price)} ({parseFloat(100 * (product.regular_price - product.discounted_price) / product.regular_price).toFixed(2)}%)</Tag>
                </td>
                <td className="td">
                    <span style={{fontSize: '1.2rem'}}>
                        {product.category.name}
                        <br/>/ {product.subcategory.name}
                    </span>
                </td>
                <td className={'td'}>
                    <Select value={trait} onChange={handleTraitChange} style={{width: '100px'}} placeholder={'Select'}>
                        <Select.Option value={'featured'}>Featured</Select.Option>
                        <Select.Option value={'bestseller'}>Bestseller</Select.Option>
                    </Select>
                </td>
                <td className={'td'}>
                    <Switch checked={flashsale}
                            onChange={(value) => flashsaleHandler(value)}/>
                </td>
                {/*<td className="td">{product.view_count}</td>*/}
                {/*<td className="td">{product.unit_sold}</td>*/}
                <td className="td">
                    <Switch checked={is_visible}
                            onChange={(value) => productVisibilityChangeHandler(value)}/>
                    {/* {product.is_visible ? <GoCheck size={20} color={"var(--color-green)"} /> : <IoClose size={20} color={"var(--color-red)"} />} */}
                </td>
                <td className="td">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Tooltip title="Edit Product" placement="top">
                            <Link to={`/products/edit-product/${product.id}`}>
                                <FiEdit size={20} style={{marginRight: "1rem", cursor: "pointer"}}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Add Color Variant" placement="top">
                            <BsPlusCircleDotted onClick={() => setShowColorVariantModal(true)} size={20}
                                                style={{marginRight: "1rem", cursor: "pointer"}}/>
                        </Tooltip>
                    </div>
                </td>
            </tr>

            {
                showColorVariantModal &&
                <Modal
                    title={`Add Color Variant of ${product.name}`}
                    open={true}
                    centered={true}
                    footer={null}
                >
                    <br/>
                    <div>
                        <label htmlFor="">Variant Color</label>
                        <Input value={color} onChange={e => setColor(e.target.value)} placeholder={'Red'}/>
                        <br/>
                        <br/>
                        <Button
                            className={'w-100'}
                            type={'primary'}
                            onClick={addColorVariant}
                            loading={colorLoading}
                            disabled={colorLoading}
                        >
                            Add Variant
                        </Button>
                    </div>
                </Modal>
            }
        </>
    );
};

export default ProductItem;
