import { Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import Layout from "../Layout";
import styles from "./WarehouseLayout.module.scss"

const WarehouseLayout = ({ children }) => {
    const location = useLocation();
    return (
        <Layout pageName="Warehouses">
            <div className={`${styles.button_top_layout} card`}>
                <Link to="/warehouses">
                    {/* TODO:Please repeat this for all buttons  */}
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses" && "primary"}>
                        Warehouse
                    </Button>
                </Link>

                <Link to="/warehouses/racks">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/racks" && "primary"}>
                        Racks
                    </Button>
                </Link>


                <Link to="/warehouses/sub-racks">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/sub-racks" && "primary"}>
                        Sub Racks
                    </Button>
                </Link>

                <Link to="/warehouses/stock-in">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/stock-in" && "primary"}>
                        Stock In
                    </Button>
                </Link>

                <Link to="/warehouses/create-stock-in">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/create-stock-in" && "primary"}>
                        Create Stock In
                    </Button>
                </Link>

                <Link to="/warehouses/stock-out">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/stock-out" && "primary"}>
                        Stock Out
                    </Button>
                </Link>

                <Link to="/warehouses/create-stock-out">
                    <Button block style={{ fontSize: 14 }} size="large" type={location.pathname === "/warehouses/create-stock-out" && "primary"}>
                        Create Stock Out
                    </Button>
                </Link>

            </div>

            {children}
        </Layout >
    );
}

export default WarehouseLayout;