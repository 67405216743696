import Layout from "../Components/Layout";
import { Row, Col, Form, Input, Button, Tooltip, Modal, message, Spin, Pagination } from "antd";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "../config/axios";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import { RiCloseCircleLine } from "react-icons/ri";

const ProductSpecification = () => {
    const [refresh, setRefresh] = useState(null);
    return (
        <Layout pageName="Product Specification">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <SpecificationAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowPaginatedSpecifications refresh={refresh} />
                </Col>
            </Row>
        </Layout>
    );
};

export default ProductSpecification;

const SpecificationAddForm = ({ setRefresh }) => {
    const [addSpecLoading, setAddSpecLoading] = useState(false);
    const [form] = Form.useForm();

    const handleAddProductSpecification = async (values) => {
        setAddSpecLoading(true);
        await axios.post("/api/specifications", values, {
            withCredentials: true,
        });
        message.success("New specification created successfully!");
        form.resetFields();
        setAddSpecLoading(false);
        setRefresh(Math.random());
    };

    return (
        <div className="sh__specificationAddForm">
            <h2 className="primary_h2">Add New Specification</h2>
            <Form name="addNewSpecification" form={form} onFinish={handleAddProductSpecification} layout="vertical">
                <Form.Item
                    label="Product Specification Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please Add Product Specification Name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Specification Bangla Name"
                    name="bn_name"
                    rules={[{ required: true, message: "Please Add Specification Bangla Name" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addSpecLoading}>
                        Add Product Specification
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const ShowPaginatedSpecifications = ({ refresh }) => {
    const [allSpecification, setAllSpecification] = useState([]);
    const [specificationLoading, setSpecificationLoading] = useState(true);
    const [specificationCount, setSpecificationCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [updateSpecLoading, setUpdateSpecLoading] = useState(false);
    const [spin, setSpin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // get all specifications in first render,changes upon page and refresh
    const getAllSpecification = async () => {
        setSpin(true);
        const res = await axios.get("/api/admin/specifications", {
            withCredentials: true,
            params: {
                limit: limit,
                page: searchTerm ? 1 : currentPage,
                search_term: searchTerm !== "" ? searchTerm : null,
            },
        });
        setSpecificationCount(res.data.count);
        setAllSpecification(res.data.results);
        setSpin(false);
        setSpecificationLoading(false);
    };
    useEffect(() => {
        getAllSpecification();
        // eslint-disable-next-line
    }, [currentPage, limit, refresh]);

    // get debounced search results from search term
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllSpecification();
        }, 300);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [searchTerm]);

    const handleUpdateProductSpecification = async (values) => {
        setUpdateSpecLoading(true);
        await axios.put(`/api/specifications/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the specifications");
        setShowModal(false);
        setUpdateSpecLoading(false);
        getAllSpecification();
    };

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Specifications`;
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    return (
        <div className="sh__showPaginatedSpecifications">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <h2 className="primary_h2">All Specification</h2>
                <Input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    placeholder="Search Specifications"
                    suffix={<AiOutlineSearch size={20} />}
                    bordered={false}
                    style={{ width: 200 }}
                />
            </div>

            {specificationLoading ? (
                <SimpleLoader height="100%" />
            ) : (
                <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                    <table>
                        <thead>
                            <tr className="tr__head">
                                <th className="th"> Name </th>
                                <th className="th"> Bangla Name </th>
                                <th className="th"> Action </th>
                            </tr>
                        </thead>
                        {allSpecification?.map((specification, index) => (
                            <tbody key={index}>
                                <tr className="tr">
                                    <td className="td"> {specification.name} </td>
                                    <td className="td"> {specification.bn_name} </td>

                                    <td className="td" style={{ cursor: "pointer", textAlign: "left" }}>
                                        <Tooltip title="Edit Specification" placement="left">
                                            <FiEdit
                                                className="sh__edit__products-icon"
                                                onClick={() => {
                                                    setModalData(specification);
                                                    setShowModal(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                    <div className="pagination__wrapper">
                        <Pagination
                            onShowSizeChange={onShowSizeChange}
                            showTotal={showTotal}
                            size="small"
                            total={specificationCount}
                            onChange={handleCurrentPage}
                            pageSize={limit}
                            current={currentPage}
                        />
                    </div>
                </Spin>
            )}
            {/* modal for edit specification*/}
            {modalData && (
                <Modal
                    visible={showModal}
                    onCancel={() => {
                        setShowModal(false);
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20} />}
                    width={600}
                    footer={null}
                    centered
                >
                    <h2 className="primary_h2"> Update Your Product Specification </h2>
                    <Form
                        name="addNewSpecification"
                        onFinish={handleUpdateProductSpecification}
                        layout="vertical"
                        initialValues={{
                            ...modalData,
                        }}
                    >
                        <Form.Item
                            label="Product Specification Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Add Product Specification Name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Specification Bangla Name"
                            name="bn_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Add Specification Bangla Name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateSpecLoading}>
                                Update Product Specification
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};
