import { Button, Col, Form, Image, Input, Modal, Pagination, Row, Spin, Switch, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";
import Layout from "../Components/Layout";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import { uploadImage } from "../api/fundamentals";
import axios from "../config/axios";
const { TextArea } = Input;

const Brands = () => {
    const [getAllBrandsLoading, setGetAllBrandsLoading] = useState(true);
    const [allBrands, setAllBrands] = useState([]);
    const [brandCount, setBrandCount] = useState(0);
    const [refresh, setRefresh] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [spin, setSpin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // get all brands and render on useEffect
    const getAllBrands = async () => {
        setSpin(true);
        const res = await axios.get("/api/public/brands", {
            withCredentials: true,
            params: {
                limit: limit,
                page: searchTerm ? 1 : currentPage,
                search_term: searchTerm !== "" ? searchTerm : null,
            },
        });
        setBrandCount(res.data.count);
        setAllBrands(res.data.results);
        setSpin(false);
        setGetAllBrandsLoading(false);
    };
    useEffect(() => {
        getAllBrands();
        // eslint-disable-next-line
    }, [refresh, currentPage, limit]);

    // get debounced search results from search term
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllBrands();
        }, 300);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [searchTerm]);

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <Layout pageName="Brands">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <BrandsAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllBrands
                        handleCurrentPage={handleCurrentPage}
                        limit={limit}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        brandCount={brandCount}
                        brands={allBrands}
                        loading={getAllBrandsLoading}
                        setRefresh={setRefresh}
                        spin={spin}
                        setSearchTerm={setSearchTerm}
                    />
                </Col>
            </Row>
        </Layout>
    );
};

export default Brands;

// sub components
const BrandsAddForm = ({ setRefresh }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [addBrandLoading, setAddBrandLoading] = useState(false);
    const [form] = Form.useForm();

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleAddBrands = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e upload at least one logo image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one logo image!");
            return;
        }
        values.image = images[0];
        setAddBrandLoading(true);
        await axios.post("/api/brands", values, { withCredentials: true });
        message.success("Added new Brand");
        setAddBrandLoading(false);
        form.resetFields();
        setImages([]);
        setRefresh(Math.random());
    };

    return (
        <div className="sh__brandsAddForm">
            <h2 className="primary_h2"> Add New Brand </h2>
            <Form name="addNewBrands" form={form} onFinish={handleAddBrands} layout="vertical">
                <Form.Item label="Brands Name" name="name" rules={[{ required: true, message: "Please Add Brand Name" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Brands Description" name="description">
                    <TextArea rows={2} />
                </Form.Item>
                <div className="uploadLogo">
                    <label style={{ fontWeight: 600 }} htmlFor="logo">
                        Logo
                    </label>
                    <div className="file-upload">
                        <div className="image-upload-wrap">
                            <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                            <div className="drag-text">
                                {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                            </div>
                        </div>
                        {images.length > 0 ? (
                            <div style={{ marginTop: "2rem" }} className="h-center">
                                {images.map((image, index) => {
                                    return <img key={index} width="50%" src={image} alt="logo" />;
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addBrandLoading}>
                        Add New Brand
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const ShowAllBrands = ({
    brands,
    loading,
    setRefresh,
    brandCount,
    limit,
    setLimit,
    currentPage,
    handleCurrentPage,
    spin,
    setSearchTerm,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [updateBrandLoading, setUpdateBrandLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    // update brands with updated data
    const handleUpdateBrand = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e upload at least one logo image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one logo image!");
            return;
        }
        values.image = images[0];
        setUpdateBrandLoading(true);
        await axios.put(`/api/brands/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the Brand");
        setUpdateBrandLoading(false);
        setShowModal(false);
        // setRefresh(Math.random());
        window.location.reload();
    };

    const brandVisibilityChangeHandler = async (id, value) => {
        await axios.put(`/api/brands/${id}`, { is_visible: value }, { withCredentials: true });
        message.success("Brand Visibility Updated Successfully!");
    };

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Brands`;
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    return (
        <div className="sh__showAllBrands">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <h2 className="primary_h2">All Brands</h2>
                <Input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    placeholder="Search Brands"
                    suffix={<AiOutlineSearch size={20} />}
                    bordered={false}
                    style={{ width: 200 }}
                />
            </div>
            {loading ? (
                <SimpleLoader height="100%" />
            ) : (
                <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                    <div className="brands_table">
                        <table>
                            <thead>
                                <tr className="tr__head">
                                    <th className="th"> Name </th>
                                    <th className="th"> Logo </th>
                                    <th className="th">Visible?</th>
                                    <th className="th"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {brands?.map((brand, index) => (
                                    <tr className="tr" key={index}>
                                        <td className="td"> {brand.name} </td>

                                        <td className="td">
                                            <Image width={65} src={brand.image} alt="logo" />
                                        </td>

                                        <td className="td">
                                            <Switch
                                                defaultChecked={brand.is_visible}
                                                onChange={(value) => brandVisibilityChangeHandler(brand.id, value)}
                                            />
                                        </td>

                                        <td className="td" style={{ cursor: "pointer", textAlign: "center" }}>
                                            <Tooltip title="Edit brand" placement="left">
                                                <FiEdit
                                                    className="sh__edit__products-icon"
                                                    onClick={() => {
                                                        setModalData(brand);
                                                        setShowModal(true);
                                                        setImages([brand.image]);
                                                    }}
                                                />
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination__wrapper">
                        <Pagination
                            showTotal={showTotal}
                            onShowSizeChange={onShowSizeChange}
                            size="small"
                            total={brandCount}
                            onChange={handleCurrentPage}
                            pageSize={limit}
                            current={currentPage}
                        />
                    </div>
                </Spin>
            )}

            {/* modal for edit category */}
            {modalData && (
                <Modal
                    open={showModal}
                    onCancel={() => {
                        setShowModal(false);
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20} />}
                    width={600}
                    style={{ top: 20 }}
                    footer={null}
                >
                    <h2 className="primary_h2"> Update Your Brand Data </h2>
                    <Form
                        name="addNewBrands"
                        onFinish={handleUpdateBrand}
                        layout="vertical"
                        initialValues={{
                            ...modalData,
                        }}
                    >
                        <Form.Item label="Brands Name" name="name" rules={[{ required: true, message: "Please Add Brand Name" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Brands Description" name="description">
                            <TextArea rows={2} />
                        </Form.Item>
                        <div className="uploadLogo">
                            <label style={{ fontWeight: 600 }} htmlFor="logo">
                                Logo
                            </label>
                            <div className="file-upload">
                                <div className="image-upload-wrap">
                                    <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                    <div className="drag-text">
                                        {xhr === "image" ? (
                                            <SimpleLoader height={"159px"} />
                                        ) : (
                                            <h3>Drag and drop or click to add Images</h3>
                                        )}{" "}
                                    </div>
                                </div>
                                {images.length > 0 ? (
                                    <div style={{ marginTop: "2rem" }} className="h-center">
                                        {images.map((image, index) => {
                                            return <img key={index} width="50%" src={image} alt="logo" />;
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateBrandLoading}>
                                Update Brand
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};
