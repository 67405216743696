import axios from '../../../config/axios';
import moment from '../../../config/moment';

//importing components
import { Modal, Row, Col, Tag } from 'antd';
import { useEffect, useState } from 'react';

const StockDetails = ({ warehouse, sku, setStockDetailsVis }) => {

    const [stockIn, setStockIn] = useState([]);
    const [stockOut, setStockOut] = useState([]);
    const [stockInCount, setStockInCount] = useState(0);
    const [stockOutCount, setStockOutCount] = useState(0);

    useEffect(() => {
        axios.get(`/api/warehouses/stock?warehouse=${warehouse}&sku=${sku}`, { withCredentials: true }).then(response => {
            // console.log(response.data);
            setStockIn(response.data.stock_in);
            setStockOut(response.data.stock_out);

            let sInCount = 0;
            let sOutCount = 0;

            //counting stock in
            for (let i = 0; i < response.data.stock_in.length; i++) {
                // console.log(response.data.stock_in[i].quantity);
                sInCount = sInCount + response.data.stock_in[i].quantity;
            }

            //counting stock in
            for (let i = 0; i < response.data.stock_out.length; i++) {
                // console.log(response.data.stock_in[i].quantity);
                sOutCount = sOutCount + response.data.stock_out[i].quantity;
            }

            setStockInCount(sInCount);
            setStockOutCount(sOutCount);
        })
    }, []);

    return (
        <Modal
            visible={true}
            onCancel={() => setStockDetailsVis(false)}
            centered={true}
            footer={null}
        >
            <Row
                gutter={[20, 20]}
                style={{ padding: '2rem 0' }}
            >
                <Col span={24}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                            Showing Stock Details for <span style={{ color: 'var(--color-green)', fontWeight: 'bold' }}>{sku}</span> ({stockIn[0]?.product?.name})
                        </span>

                        <br />
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <span style={{ display: 'block', marginBottom: '.2rem' }}>
                                Total Stock In: <Tag>{stockInCount}</Tag>
                            </span>
                            <span style={{ display: 'block', marginBottom: '.2rem' }}>
                                Total Stock Out: <Tag>{stockOutCount}</Tag>
                            </span>
                        </div>
                        <span>Current Stock: <Tag color={'red'}>{stockInCount - stockOutCount}</Tag></span>
                    </div>
                </Col>
                <Col span={12}>
                    <h3 style={{ paddingLeft: '.5rem', borderBottom: '1px solid var(--color-grey-light-2)' }}>Stock In Records</h3>
                    <table>
                        <thead>
                            <th style={{ textAlign: 'left' }}>Time</th>
                            <th style={{ textAlign: 'left' }}>Quantity</th>
                        </thead>
                        <tbody>
                            {
                                stockIn.map((stock, index) => {
                                    return <tr key={index}>
                                        <td>{moment(stock.timestamp).format('MMMM D, YYYY - LT')}</td>
                                        <td>
                                            <Tag>
                                                {stock.quantity}
                                            </Tag>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </Col>
                <Col span={12}>
                    <h3 style={{ paddingLeft: '.5rem', borderBottom: '1px solid var(--color-grey-light-2)' }}>Stock Out Records</h3>
                    <table>
                        <thead>
                            <th style={{ textAlign: 'left' }}>Time</th>
                            <th style={{ textAlign: 'left' }}>Quantity</th>
                        </thead>
                        <tbody>
                            {
                                stockOut.map((stock, index) => {
                                    return <tr key={index}>
                                        <td>{moment(stock.timestamp).format('MMMM D, YYYY - LT')}</td>
                                        <td>
                                            <Tag>
                                                {stock.quantity}
                                            </Tag>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Modal>
    )
}

export default StockDetails;