import { createContext, useState } from "react";

export const GlobalStates = createContext();

const GlobalStatesProvider = ({ children }) => {
    const [sidebarScrollPosition, setSidebarScrollPosition] = useState(0);
    const [theme, setTheme] = useState("light");

    return (
        <GlobalStates.Provider
            value={{
                sidebarScrollPosition,
                setSidebarScrollPosition,
                theme,
                setTheme,
            }}
        >
            {children}
        </GlobalStates.Provider>
    );
};

export default GlobalStatesProvider;
