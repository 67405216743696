import Layout from '../../Components/Layout';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../config/axios';
import SimpleLoader from '../../Components/SimpleLoader/SimpleLoader';
import { Input, Row, Col, Select, message, Button, Divider, InputNumber, Form, Radio, Modal, Checkbox } from 'antd';
import styles from './CreateOrder.module.scss';
import NoData from '../../Components/Library/NoData';
import { AiFillDelete } from "react-icons/ai";
import { calculateGroceryProductStock, getGroceryProductSku } from '../../utils/helpers';
import { transporters, adminLabels } from '../../utils/data';
import { IoEnter } from 'react-icons/io5';


const CreateOrder = ({match}) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);


    // user info states
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userInfoLoading, setUserInfoLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [userCheckCompleted, setUserCheckCompleted] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddresses, setSelectedAddresses] = useState();
    const [cities, setCities] = useState([]);
    const [zones, setZones] = useState([]);
    const [city, setCity] = useState(null);
    const [zone, setZone] = useState(null);

    // products states
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [, setRefreshX] = useState(null);

    // order states
    const [orderItems, setOrderItems] = useState([]);
    const [variant, setVariant] = useState(null);
    const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [discount, setDiscount] = useState(0);

    //order states
    const [transporter, setTransporter] = useState(null);
    const [privateNote, setPrivateNote] = useState('');

    const handleCheckUser = async () => {
        if (phoneNumber.length !== 11) {
            message.error('Phone Number must be 11 digit!');
            return;
        }

        setUserInfoLoading(true);

        const res = await axios.get(`/api/users/admin/search`, {
            withCredentials: true,
            params: {
                phone_number: phoneNumber,
            },
        });

        let data = res.data.results;
        if (data.found) {
            setUserInfo({
                user_id: data.user.id,
                name: data.user.name,
                phone_number: data.user.phone_number,
                city: data.user.address?.city,
                pathao_city_id: data.user.address?.pathao_city_id,
                zone: data.user.address?.zone,
                pathao_zone_id: data.user.address?.pathao_zone_id,
                address: data.user.address?.address,
            });

            if (data.user.address) {
                setCity(data.user.address?.city);
                setZone(data.user.address?.zone);
                if (data.user.address?.pathao_city_id === 1) {
                    setDeliveryCharge(70);
                } else {
                    setDeliveryCharge(150);
                }
                await getDeliveryZones(data.user.address?.pathao_city_id);
            }

            message.success('User already registered');
        } else {
            setUserInfo({});
            message.info('User not registered');
        }

        setUserInfoLoading(false);
        setUserCheckCompleted(true);
    }

    const getDeliveryZones = (city_id) => {
        if (city_id) {
            axios.get(`/api/orders/deliveries/pathao/zones?city_id=${city_id}`, {withCredentials: true}).then(response => {
                setZones(response.data.results);
            });
        }
    }

    useEffect(() => {
        axios.get('/api/orders/deliveries/pathao/cities', {withCredentials: true}).then(response => {
            setCities(response.data.results);
        })
    }, []);


    // get products from server to include in order Items
    useEffect(() => {
        // fetching all products
        const fetchAllProducts = async () => {
            const res = await axios.get(`/api/products`, {
                withCredentials: true,
                params: {
                    name: searchTerm,
                },
            });

            setProducts(res.data.results);
        };

        // call fetchAllProducts function with debounced callback
        const delayDebounceFn = setTimeout(() => {
            fetchAllProducts();
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);


    // defining removeArrItem function
    const removeArrItem = (arr, item) => {
        var index = arr.indexOf(item);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };

    //defining removeOrderItem function
    const removeOrderItem = (item) => {
        setOrderItems(removeArrItem(orderItems, item));
        setRefreshX(Math.random());
    };

    // defining calculateProductStock function
    const calculateProductStock = (product) => {
        let stock = 0;

        for (let i = 0; i < product.clones.length; i++) {
            stock = stock + product.clones[i].stock;
        }

        return stock + product.stock;
    }


    //defining addItemToOrder function
    const addItemToOrder = () => {

        if (selectedProduct === null) {
            message.error("Please select an item!");
            return;
        }


        if (selectedProductQuantity > variant.stock) {
            if (window.confirm(`We only have ${variant.stock} unit of this product in stock! Add Anyway?`) === false) {
                return;
            }
        }


        let order_item = {
            name: selectedProduct.name,
            slug: selectedProduct.slug,
            sku: variant.sku,
            color: selectedProduct.color,
            size: variant.size,
            order_time_price: selectedProduct.discounted_price,
            order_time_regular_price: selectedProduct.regular_price,
            quantity: selectedProductQuantity,
            image: selectedProduct.featured_image,
            discount: selectedProduct.regular_price - selectedProduct.discounted_price,
            variant: variant.id
        }

        setSelectedProduct(null);
        setVariant(null);
        setSelectedProductQuantity(1);

        setOrderItems((orderItems) => orderItems.concat(order_item));
    };

    //defining calculateOrderTotal function
    const calculateOrderTotal = () => {
        let orderTotal = 0;
        for (let i = 0; i < orderItems.length; i++) {
            orderTotal = orderTotal + (orderItems[i].order_time_price * orderItems[i].quantity);
        }

        orderTotal = (orderTotal + parseInt(deliveryCharge)) - parseInt(discount);

        return Math.ceil(orderTotal);
    }

    //defining calculateSubTotal function
    const calculateOrderSubTotal = () => {
        let subTotal = 0;
        for (let i = 0; i < orderItems.length; i++) {
            subTotal = subTotal + (orderItems[i].order_time_price * orderItems[i].quantity);
        }

        return subTotal;
    }


    // defining createNewOrder function
    const createNewOrder = async (values) => {
        setLoading(true);

        if (orderItems.length === 0) {
            message.error('Please add at least 1 product!');
            return;
        }

        const payload = {
            order_items: orderItems,
            sub_total: calculateOrderSubTotal(),
            delivery_charge: deliveryCharge,
            discount: discount,
            total: calculateOrderTotal(),
            city: city,
            zone: zone,
            user_id: userInfo.id ? userInfo.id : null,
            ...values
        }

        console.log(payload)
        try {
            const response = await axios.post('/api/admin/orders/place', payload, {withCredentials: true});
            message.success('Order placed successfully!');
            history.push('/orders');
        } catch (err) {
            message.error(err.response.data.msg);
        }

        // history.push('/orders');
        setLoading(false);
    }

    return (
        <Layout pageName="Create Order">

            {
                !userCheckCompleted ?
                    <div className={'card d-flex align-items-center justify-content-center'} style={{height: '80vh'}}>
                        <div style={{width: '300px'}}
                             className={'d-flex flex-column align-items-center justify-content-center'}>
                            <label htmlFor="" className={'fs-3'}>Enter Customer's Phone Number</label>
                            <div className={'d-flex gap-4 mt-2'}>
                                <Input
                                    size={'large'}
                                    onChange={e => setPhoneNumber(e.target.value)}
                                    onPressEnter={handleCheckUser}
                                />
                                <Button
                                    size={'large'}
                                    type={'primary'}
                                    loading={userInfoLoading}
                                    disabled={userInfoLoading}
                                    onClick={handleCheckUser}
                                >
                                    <IoEnter size={25} color={'var(--color-light)'}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card">
                        <Form
                            layout={'vertical'}
                            initialValues={{
                                ...userInfo,
                                discount: 0,
                                delivery_charge: deliveryCharge,
                                payment_status: 'due',
                                payment_method: 'cod',
                            }}
                            scrollToFirstError={true}
                            onFinish={createNewOrder}
                        >
                            <Row gutter={[15, 15]}>
                                <Col lg={24}>
                                    <Form.Item name={'sales_channel'} label={'Sales Channel'} required={true}
                                               rules={[{required: true}]}>
                                        <Radio.Group buttonStyle={'solid'}>
                                            <Radio.Button value={'others'}>Website</Radio.Button>
                                            <Radio.Button value={'facebook'}>Facebook</Radio.Button>
                                            <Radio.Button value={'instagram'}>Instagram</Radio.Button>
                                            <Radio.Button value={'others'}>Others</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Divider>Personal Info</Divider>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'phone_number'} label={'Phone Number'} required={true}
                                               rules={[{required: true}]}>
                                        <Input placeholder={'Phone Number'} size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'name'} label={'Name'} required={true}
                                               rules={[{required: true}]}>
                                        <Input placeholder={'Full Name'} size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Divider>Order Details</Divider>
                                </Col>
                                <Col lg={8}>
                                    <label htmlFor="">Search Products</label>
                                    <Select
                                        showSearch
                                        style={{width: "100%"}}
                                        onChange={(e) => {
                                            setSelectedProduct(products[e]);
                                        }}
                                        onSearch={(value) => {
                                            setSearchTerm(value);
                                        }}
                                        value={selectedProduct}
                                        bordered={true}
                                        placeholder="Enter product name or SKU"
                                        filterOption={false}
                                        size="large"
                                    >
                                        {products.map((product, index) => {
                                            return (
                                                <Select.Option key={product.id} value={index}>
                                                    <img src={product.featured_image} className={styles.option_image}
                                                         alt="product"/>
                                                    <span>
                                                    {product.name}
                                                </span>
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                                <Col lg={16}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>SL.</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>SKU</th>
                                            <th>Color</th>
                                            <th>Size</th>
                                            <th>Qty</th>
                                            <th>Unit Price</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                        {
                                            orderItems.map((order_item, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img src={order_item.image} style={{width: '50px'}} alt=""/>
                                                    </td>
                                                    <td>{order_item.name}</td>
                                                    <td>{order_item.sku}</td>
                                                    <td>{order_item.color}</td>
                                                    <td>{order_item.size}</td>
                                                    <td>{order_item.quantity}</td>
                                                    <td>{order_item.order_time_price}</td>
                                                    <td>{order_item.order_time_price * order_item.quantity}</td>
                                                    <td>
                                                        <Button
                                                            danger={true}
                                                            type={'primary'}
                                                            onClick={() => removeOrderItem(order_item)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </Col>
                                <Col span={24}>
                                    <Divider>Delivery & Payment Details</Divider>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'pathao_city_id'} label={'City'} required={true}
                                               rules={[{required: true}]}>
                                        <Select
                                            size={'large'}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            onChange={(value, data) => {
                                                setCity(data.city);
                                                getDeliveryZones(value)
                                            }}
                                        >
                                            {
                                                cities.map((city, index) => {
                                                    return <Select.Option city={city.city_name} value={city.city_id}
                                                                          key={index}>
                                                        {city.city_name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'pathao_zone_id'} label={'Zone'} required={true}
                                               rules={[{required: true}]}>
                                        <Select
                                            size={'large'}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            onChange={(value, data) => {
                                                setZone(data.zone);
                                            }}
                                        >
                                            {
                                                zones.map((zone, index) => {
                                                    return <Select.Option zone={zone.zone_name} value={zone.zone_id}
                                                                          key={index}>
                                                        {zone.zone_name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'address'} label={'Address'} required={true}
                                               rules={[{required: true}]}>
                                        <Input.TextArea size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={4}>
                                    <Form.Item name={'delivery_charge'} label={'Delivery Charge'} required={true}
                                               rules={[{required: true}]}>
                                        <Input
                                            type={'number'}
                                            size={'large'}
                                            onChange={e => setDeliveryCharge(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={4}>
                                    <Form.Item name={'discount'} label={'Discount'}>
                                        <Input
                                            type={'number'}
                                            size={'large'}
                                            onChange={e => setDiscount(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'payment_method'} label={'Payment Method'} required={true}
                                               rules={[{required: true}]}>
                                        <Select size={'large'}>
                                            <Select.Option value={'cod'}>COD (Cash on Delivery)</Select.Option>
                                            <Select.Option value={'cash'}>Cash</Select.Option>
                                            <Select.Option value={'bkash'}>bKash</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'payment_status'} label={'Payment Status'} required={true}
                                               rules={[{required: true}]}>
                                        <Select size={'large'}>
                                            <Select.Option value={'due'}>Due</Select.Option>
                                            <Select.Option value={'paid'}>Paid</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'notes'} label={'Notes (Public)'}>
                                        <Input.TextArea size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item name={'private_notes'} label={'Notes (Private)'}>
                                        <Input.TextArea size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name={'send_sms'}
                                        label={'Send SMS to Customer'}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Check to Send</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} offset={12}>
                                    <div className={styles.finalCalculation}>
                                        <p>
                                            Sub Total : {calculateOrderSubTotal()} BDT
                                        </p>
                                        <p>
                                            Delivery Charge : {deliveryCharge} BDT
                                        </p>
                                        <p className={'text-danger'}>
                                            Special Discount : {discount} BDT
                                        </p>
                                        <Divider dashed className={styles.create_order_divider}/>
                                        <p style={{color: 'var(--color-green)'}}>
                                            Total : {calculateOrderTotal()} BDT
                                        </p>
                                    </div>
                                </Col>

                                <Col lg={24}>
                                    <Button
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        loading={loading}
                                        disabled={calculateOrderSubTotal() === 0}
                                    >
                                        Create Order
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
            }

            {
                selectedProduct &&
                <Modal
                    open={true}
                    footer={null}
                    onCancel={() => setSelectedProduct(null)}
                    title={'Select Product Variant'}
                >
                    <div>
                        {
                            !variant ?
                                <table>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>SKU</th>
                                        <th>Size</th>
                                        <th>Stock</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        selectedProduct.variants.map(variant => {
                                            return <tr key={variant.id}>
                                                <td>
                                                    <img src={selectedProduct.featured_image} style={{width: '50px'}}
                                                         alt=""/>
                                                </td>
                                                <td>{selectedProduct.name}</td>
                                                <td>{variant.sku}</td>
                                                <td>{variant.size}</td>
                                                <td>{variant.stock}</td>
                                                <td>
                                                    <Button
                                                        size={'large'}
                                                        type={'primary'}
                                                        onClick={() => setVariant(variant)}
                                                    >
                                                        Select
                                                    </Button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </table>
                                :
                                <div className={'d-flex flex-column align-items-center mt-4'}>
                                    <b className={'fs-2'}>Enter Quantity for {selectedProduct.name}</b>
                                    <b>SKU: {variant.sku}</b>
                                    <b>Size: {variant.size}</b>
                                    <br/>
                                    <div className={'d-flex gap-4'}>
                                        <Input
                                            style={{width: '100px'}}
                                            size={'large'}
                                            value={selectedProductQuantity}
                                            type={'number'}
                                            onChange={e => setSelectedProductQuantity(e.target.value)}
                                        />
                                        <Button
                                            style={{width: '100px'}}
                                            size={'large'}
                                            type={'primary'}
                                            onClick={addItemToOrder}
                                        >
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </Modal>
            }

        </Layout>
    );
}

export default CreateOrder;