import axios from "../../config/axios";
import React, { useState, useRef, useEffect } from 'react';

// importing components
import { Select, Input, InputNumber, message } from "antd";

// importing icons
import { AiFillDelete } from 'react-icons/ai';

const { Option } = Select;

const StockOutProduct = ({ prod, products, setProducts, index, setRefresh, allRacksForSelectedWarehouse, warehouse }) => {
    const [allSubRacks, setAllSubRacks] = useState([]);
    const subrackRef = useRef();
    const [check, setCheck] = useState(null);
    const [stock, setStock] = useState(0);
    const [stockLoading, setStockLoading] = useState(true);
    const [rack, setRack] = useState(prod.rack);

    useEffect(() => {

        axios.get(`/api/warehouses/stock?warehouse=${warehouse}&sku=${prod.sku}`, { withCredentials: true }).then(response => {

            let sInCount = 0;

            //counting stock in
            for (let i = 0; i < response.data.stock_in.length; i++) {
                // console.log(response.data.stock_in[i].quantity);
                sInCount = sInCount + response.data.stock_in[i].quantity;
            }

            // console.log(sInCount);
            setStock(sInCount);
            setStockLoading(false);

        });

    }, []);

    useEffect(() => {
        if (!stockLoading) {
            console.log('SO Item and warehouse: ', prod.sku, warehouse);

            // axios.get(`/api/warehouses/stock?warehouse=${warehouse}&sku=${prod.sku}`, { withCredentials: true }).then(response => {

            //     let sInCount = 0;

            //     //counting stock in
            //     for (let i = 0; i < response.data.stock_in.length; i++) {
            //         // console.log(response.data.stock_in[i].quantity);
            //         sInCount = sInCount + response.data.stock_in[i].quantity;
            //     }

            //     // console.log(sInCount);
            //     setStock(sInCount);

            if (stock <= 0) {
                message.error('Item not in stock!');
            }

            if (products[index].quantity > stock) {
                message.error(`Current stock: ${stock}`);
                products[index].quantity = stock;
                setRefresh(Math.random());
            }
            // });
        }
    }, [products[index].quantity, check]);

    const getSubRacksForRack = async (id) => {
        const res = await axios.get(`/api/warehouses/subracks`, {
            withCredentials: true, params: {
                rack: id
            }
        })
        setAllSubRacks(res.data);
    }

    return (

        <tr className="tr">
            <td className="td">
                {prod.sku}
            </td>
            <td className="td">
                {prod.name}
            </td>
            <td className="td">

                <Select
                    placeholder="Select Rack"
                    style={{ width: 170 }}
                    onChange={(value, { code }) => {
                        products[index].rack = value
                        products[index].rack_code = code
                        getSubRacksForRack(value)
                        products[index].subrack = ''
                        products[index].subrack_code = ''
                        subrackRef.current.selectedIndex = -1
                        setRefresh(Math.random())
                        setRack(value)
                    }}
                    defaultValue={rack}
                >

                    {allRacksForSelectedWarehouse?.map((option, index) => (
                        <Option value={option.id} key={index} code={option.code}>
                            {option.code}
                        </Option>
                    ))}
                </Select>

            </td>

            <td className="td">
                <Select placeholder="Select Sub Rack"
                    onClick={() => { getSubRacksForRack(rack) }}
                    defaultValue={prod?.subrack_code}
                    style={{ width: 170 }}
                    onChange={(value, { code }) => {
                        products[index].subrack = value;
                        products[index].subrack_code = code;
                        setRefresh(Math.random());
                    }}
                    ref={subrackRef}
                >
                    {allSubRacks?.map((option, index) => (
                        <Option value={option.id} key={index} code={option.code}>
                            {option.code}
                        </Option>
                    ))}
                </Select>

            </td>

            <td className="td" style={{ display: 'flex', flexDirection: 'column' }}>
                <InputNumber
                    style={{ marginTop: '1.8rem' }}
                    value={prod.quantity}
                    defaultValue={prod.quantity}
                    onChange={(value) => {
                        console.log(value)
                        products[index].quantity = value;
                        setCheck(Math.random());
                        setRefresh(Math.random());
                    }} />
                <span> Stock: {stock}</span>
            </td>

            <td className="td">
                <Input
                    defaultValue={prod.remarks}
                    placeholder="Add Remarks"
                    onChange={(e) => {
                        products[index].remarks = e.target.value
                        setRefresh(Math.random())
                    }}
                />
            </td>

            <td className="td">
                <AiFillDelete
                    style={{ cursor: 'pointer' }}
                    size={20}
                    onClick={() => {
                        products.splice(index, 1);
                        setProducts([...products]);
                    }}
                />
            </td>
        </tr>

    );
}

export default StockOutProduct;