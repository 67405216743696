import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import Layout from '../../Components/Layout';
import styles from "./CreateCustomer.module.scss";
import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { Link } from "react-router-dom";


const CreateCustomer = () => {

    const [form] = Form.useForm();

    // step states
    const [step, setStep] = useState(1);

    // loading states
    const [loading, setLoading] = useState(false);

    // location states
    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);

    // customer states
    const [customerId, setCustomerId] = useState(null);

    // get division first to select division
    const getDivisions = async () => {
        const res = await axios.get("/api/fundamentals/get-locations");
        setDivisions(res.data);
    }
    useEffect(() => {
        getDivisions();
    }, []);

    // get districts based on division
    const getDistricts = async (ref_id) => {
        const res = await axios.get(`/api/fundamentals/get-locations`, {
            params: {
                type: "district",
                ref_id: ref_id
            }
        });
        setDistricts(res.data);
    }

    // get subdistricts based on district
    const getSubdistricts = async (ref_id) => {
        const res = await axios.get(`/api/fundamentals/get-locations`, {
            params: {
                type: "subdistrict",
                ref_id: ref_id
            }
        });
        setSubdistricts(res.data);
    }


    // defining createNewCustomer function
    const createNewCustomer = async (values) => {

        // destructure form values for preparing payload
        const { name, phone_number, division, district, area, address, referred_by } = values;

        // preparing payload
        const payload = {
            name,
            phone_number,
            address: {
                division,
                district,
                area,
                address,
                label: "home",
                primary: true,
            },
            referred_by: referred_by ? referred_by : null,
        }

        try {

            // set loading to true
            setLoading(true);

            // send request to create new customer
            const res = await axios.post("/api/users/admin/create-customer", payload, { withCredentials: true });
            console.log(res.data);

            // set customer id
            setCustomerId(res.data.user_id);

            // reset forms after successful creation of customer
            form.resetFields();

            // if successful, proceed to next step
            setStep(2);

        } catch (error) {
            if (error.response.status === 406) {
                message.error(`An user with this phone number already exists!`);
            } else {
                message.error(`Something went wrong. Contact technical team!`);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Layout pageName="Create Customer">
            <div className={`card`}>
                {step === 1 && (
                    <>
                        <h2 className={`primary_h2 + ${styles.createHeading}`}> Create New Customer </h2>
                        <div className={styles.createCustomerForm}>
                            <Form
                                name="Create_Customer"
                                onFinish={createNewCustomer}
                                autoComplete="on"
                                form={form}
                                layout="vertical"
                                scrollToFirstError

                            >
                                <Row gutter={[16, 0]}>
                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Name *"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input Customer Name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Shakib Khan" />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Phone Number *"
                                            name="phone_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input Customer Phone Number!',
                                                },
                                            ]}
                                        >
                                            <Input type="number" placeholder="01710111111" />
                                        </Form.Item>
                                    </Col>


                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Division *"
                                            name="division"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Customer Division!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                onChange={(_, { ref_id }) => {
                                                    getDistricts(ref_id);
                                                }}
                                                placeholder="Select Division"
                                            >
                                                {divisions.map((division, index) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            ref_id={division.ref_id}
                                                            value={division.name}
                                                        >
                                                            {division.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="District *"
                                            name="district"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Customer District!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                onChange={(_, { ref_id }) => {
                                                    getSubdistricts(ref_id);
                                                }}
                                                placeholder="Select District"
                                            >
                                                {districts.map((district, index) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            ref_id={district.ref_id}
                                                            value={district.name}
                                                        >
                                                            {district.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Area *"
                                            name="area"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Customer Area!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                placeholder="Select Area"
                                            >
                                                {subdistricts.map((district, index) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={district.name}
                                                        >
                                                            {district.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Address *"
                                            name="address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Input Customer Address!',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea autoSize />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Referral Code"
                                            name="referred_by"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                            className={styles.referralCode}
                                        >
                                            <Input placeholder="NEX8723232" />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={24} sm={24} xs={24}>
                                        <Form.Item>
                                            <Button
                                                size="large"
                                                block
                                                type="primary"
                                                htmlType="submit"
                                                loading={loading}
                                                className={styles.createCustomerSubmitButton}
                                            >
                                                Create New Customer
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                    </>
                )}

                {step === 2 && (
                    <div className={styles.createCustomerSuccess}>
                        <div className={styles.animatedTick}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className={`${styles.path} ${styles.circle}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className={`${styles.path} ${styles.check}`} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </div>
                        <div className={styles.createCustomerContent}>
                            <h2>Customer Created Successfully!</h2>
                        </div>
                        <div className={styles.actionButtons}>
                            <Link to="/">
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                >
                                    Go Home
                                </Button>
                            </Link>

                            <Link to={`/create-order/${customerId}`}>
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                >
                                    Create Order For This Customer
                                </Button>
                            </Link>

                        </div>
                    </div>
                )}


            </div>

        </Layout >
    );
}

export default CreateCustomer;