// import * as xlsx from 'xlsx';
import ReactJson from 'react-json-view'

//importing components
import { useState } from 'react';
import Layout from '../../Components/Layout';

const ExcelToJson = () => {

    const [rawData, setRawData] = useState(null);

    //defining readUploadFile function
    const readUploadFile = (e) => {
        // e.preventDefault();
        // if (e.target.files) {
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         const rawData = e.target.result;
        //         const workbook = xlsx.read(rawData, { type: "array" });
        //         const sheetName = workbook.SheetNames[1];
        //         const worksheet = workbook.Sheets[sheetName];
        //         const json = xlsx.utils.sheet_to_json(worksheet);
        //         console.log(json);
        //         setRawData(json);
        //
        //     };
        //     reader.readAsArrayBuffer(e.target.files[0]);
        // }
    }
    return (
        <Layout
            pageName={'Excel to JSON'}
        >
            <div className='card'>
                <input
                    id='excelUpload'
                    type="file"
                    onChange={readUploadFile}
                />
                <br />
                <br />

                {
                    rawData &&
                    <ReactJson src={rawData} />
                }
            </div>
        </Layout>
    )
}

export default ExcelToJson;