import styles from './Calculator.module.scss';

import Layout from '../../Components/Layout';
import { useState } from 'react';
import { useRef } from 'react';

const Calculator = () => {

    const [purchasePrice, setPurchasePrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [commission, setCommission] = useState(null);
    const [vat, setVat] = useState(null);
    const [profit, setProfit] = useState(null);

    const purchasePriceRef = useRef(null);

    //defining calculateCommission function
    const calculateCommission = (e) => {
        e.preventDefault();

        let vat = (salePrice * 5) / 100;
        let profit = salePrice - purchasePrice - vat;
        console.log(profit);
        let comissionPercentage = (profit * 100) / salePrice;

        setVat(vat);
        setProfit(profit);
        setCommission(parseFloat(comissionPercentage).toFixed(2));

        setSalePrice('');
        setPurchasePrice('');

        purchasePriceRef.current.focus();
    }

    return (
        <Layout pageName="Calculator">
            <div className={styles.card}>
                <h1>Commission Calculator</h1>

                <form onSubmit={calculateCommission}>
                    <label htmlFor="">Purchase Price</label>
                    <input ref={purchasePriceRef} placeholder='Enter Purchase Price' type="number" value={purchasePrice} onChange={e => setPurchasePrice(e.target.value)} />

                    <label htmlFor="">Sale Price</label>
                    <input placeholder='Enter Sale Price' type="number" value={salePrice} onChange={e => setSalePrice(e.target.value)} />

                    <button>Calculate</button>
                </form>

                {
                    commission && <div className={styles.result}>
                        <span className={styles.commission}>Commission: {commission} % ({profit} Taka)</span>
                        <span style={{ color: 'red' }}>VAT: 5% ({vat} Taka)</span>
                        {/* <span style={{ color: 'var(--color-green)' }}>Profit: {profit} Taka</span> */}
                    </div>
                }
            </div>
        </Layout>
    )
}

export default Calculator;