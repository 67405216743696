import React, { Component } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

class ReorderImages extends Component {
    constructor(props) {
        super();
        const { images } = props;
        this.state = {
            images,
            forceReRender: null,
        };
        this.dragId = "";
    }

    // update this component as new image added or removed
    static getDerivedStateFromProps(props, state) {
        if (props.images !== state.images) {
            return {
                images: props.images,
            };
        }
        return null;
    }

    imageDragOver = (ev) => ev.preventDefault();

    imageDragStart = (ev) => (this.dragId = ev.target.id);

    // handling on dropImage event
    dropImage = (ev) => {
        ev.preventDefault();
        const dragElement = this.dragId.split("-");
        let dragIndex = "";
        if (dragElement.length > 1) {
            dragIndex = dragElement[0];
        }

        const dropElement = ev.target.id.split("-");
        let dropIndex = "";
        if (dropElement.length > 1) {
            dropIndex = dropElement[0];
        }

        if (dragIndex !== "" && dropIndex !== "") {
            const { images } = this.state;
            const dragObject = images[dragIndex];
            images.splice(dragIndex, 1);
            images.splice(dropIndex, 0, dragObject);
            this.setState({ images });
            this.props.callback(images);
        }

        if (this.props.setFImageChanged) {
            this.props.setFImageChanged(true);
        }
    };

    // defining remove item once method
    removeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };

    //defining removeImageFromGallery function
    removeImageFromGallery = (image) => {
        this.setState({ images: this.removeItemOnce(this.state.images, image) });
        this.setState({ forceReRender: Math.random() });
    };

    render() {
        const { images } = this.state;
        return (
            <div className="image-gallery">
                {images &&
                    images.length > 0 &&
                    images.map((image, index) => {
                        return (
                            <div
                                className="image-gallery__item"
                                key={index}
                                id={`${index}-div`}
                                onDrop={this.dropImage}
                                onDragOver={this.imageDragOver}
                            >
                                <div
                                    className="icon d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: "white",
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        border: "1px solid var(--color-red)",
                                    }}
                                    onClick={() => {
                                        this.removeImageFromGallery(image);
                                    }}
                                >
                                    <AiFillCloseCircle size={20} color={"var(--color-red)"} />
                                </div>

                                <img
                                    id={`${index}-img`}
                                    draggable={true}
                                    onDragStart={this.imageDragStart}
                                    style={{ width: "150px", height: "150px", cursor: "grabbing" }}
                                    key={image}
                                    src={image}
                                    alt={index}
                                    data-holder-rendered="true"
                                />
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default ReorderImages;
