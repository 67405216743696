import { useEffect, useState } from 'react';
import axios from '../../config/axios';
import Barcode from 'react-barcode';
import {
    Input,
    Button
} from 'antd';
import styles from './BarcodePrint.module.scss';

const BarcodePrint = (props) => {

    const [product, setProduct] = useState({});

    useEffect(() => {
        axios.get(`/api/products/mod/${props.match.params.productID}`, { withCredentials: true }).then((response) => {
            setProduct(response.data);
            console.log(response.data);
        })
    }, []);

    return (
        <div className={styles.barcode}>
            <Barcode width={3} height={160} value={product.sku} />
            <span>{product.name}</span>
        </div>
    )
}

export default BarcodePrint;