import { useState, useEffect } from 'react';
import moment from 'moment';
import axios from '../../config/axios';
import styles from './OrderInvoice.module.scss';

// importing components
import { Col, Row } from 'antd';
import SimpleLoader from '../../Components/SimpleLoader/SimpleLoader';

// importing helper functions
import { formattedPrice } from '../../utils/helpers';

// importing icons
import { FaInstagramSquare, FaFacebookSquare } from "react-icons/fa";


const OrderInvoice = ({match}) => {

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});

    useEffect(() => {
        // get order details from params
        const getOrderDetails = async () => {
            setLoading(true);
            const res = await axios.get(`/api/orders/${match.params.id}`, {withCredentials: true});
            setOrder(res.data);
            setLoading(false);
        }
        getOrderDetails()
    }, [match.params.id]);

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                // window.print()
            }, 1500);
        }
    }, [loading]);


    return (
        <div className="print_invoice_container">

            {loading ? (
                <SimpleLoader height={"100vh"} white={false}/>
            ) : (
                <div className="print_invoice" style={{position: 'relative'}}>

                    <img src="/logo.png" alt="" className={styles.watermark}/>

                    <div className={"hv-center fc"} style={{borderBottom: '1px solid black', paddingBottom: '2rem'}}>
                        <div className={'d-flex justify-content-between gap-5'}>
                            <img src="/logo.png" style={{height: '80px', width: 'fit-content', marginTop: '18px'}} alt="logo" className="print_logo"/>
                            <div className={'d-flex flex-column'}>
                                <span className="print_nexa_title" style={{fontSize: '4rem'}}>China Bangladesh Import Hub</span>
                                <span style={{fontSize: '1.4rem'}}>Address: House- 32, Road-9/C, Sector- 5, Uttara, Dhaka, Bangladesh</span>
                                <span style={{fontSize: '1.4rem'}}>Hotline: 01894-782733, Email: cbih.ltd@gmail.com, Web: www.cbih.com.bd</span>
                            </div>
                        </div>

                    </div>

                    {/*<hr className="print_hr" style={{marginTop: '1rem', marginBottom: '1rem'}}/>*/}

                    {/*<div className={"hv-center fc"}>*/}
                    {/*    <span>Government of {"People's"} Republic of Bangladesh, National Board of Revenue</span>*/}
                    {/*    <span className="print_tax_invoice">Tax Invoice (MUSHAK-6.3)</span>*/}
                    {/*    /!*<span style={{ fontSize: "1.1rem" }}>All monetary values are in Bangladeshi Taka</span>*!/*/}
                    {/*    <span>This invoice was automatically generated at our system. No signature is required</span>*/}
                    {/*    /!*<span style={{ fontSize: "1.1rem" }}>*All prices are VAT inclusive</span>*!/*/}
                    {/*</div>*/}

                    <div className={styles.top_info}>
                        <span className={styles.order_id}>Order ID: <span style={{fontWeight: 'bold'}}>{order.id}</span></span>
                        <div className='fc' style={{fontSize: '1.2rem'}}>
                            <span>Payment Method: <span style={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase'
                            }}>{order.payment_method}</span></span>
                            <span>Date: <span
                                style={{fontWeight: 'bold'}}>{moment(order.created_at).format('MMM D, YYYY - LT')}</span></span>
                        </div>
                    </div>

                    <table className="print_table">
                        <thead>
                        <tr style={{marginBottom: 5}}>
                            <th>Item Image</th>
                            <th>Item Name & Description</th>
                            <th>SKU</th>
                            <th style={{width: '100px'}}>Price</th>
                            <th>Qty</th>
                            <th style={{textAlign: "right"}}>Item Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.order_items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <img src={item.image} width="60px" alt="product_item_image"/>
                                    </td>
                                    <td>
                                        <div className="print_item_name">{item.name}</div>
                                        {/* <div>Size: {item.name}</div> */}
                                        <div>Color: {item.color}</div>
                                    </td>
                                    <td style={{fontWeight: 'bold'}}>{item.sku}</td>
                                    <td>{formattedPrice(item.order_time_price)}</td>
                                    <td>
                                        <span>{item.quantity}</span>
                                    </td>
                                    <td style={{textAlign: "right"}}>{formattedPrice(item.quantity * item.order_time_price)}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <Row gutter={[16, 0]} style={{borderTop: "1px solid var(--color-grey-light-4)"}}>
                        <Col span={12}>

                            <div style={{marginTop: '2rem'}}>
                                {
                                    order.payment_status === 'paid' ?
                                        <img src={'/paid.png'} alt="" height={'150px'}/>
                                        :
                                        <img src={'/due.png'} alt="" height={'150px'}/>
                                }
                            </div>

                        </Col>
                        <Col span={12}>
                            <div style={{marginTop: "2rem"}}>
                                <div>
                                    <div className="print_h_even" style={{marginBottom: "1rem"}}>
                                        <span>Sub Total</span>
                                        <span>{formattedPrice(order.sub_total)}</span>
                                    </div>
                                    <hr className="print_hr" style={{marginTop: 0, marginBottom: "1rem"}}/>
                                    {/* <div className="print_h_even">
                                            <div className="fc" style={{ marginBottom: '1rem' }}>
                                                <span>Government VAT (Value Added Tax)</span>
                                                <span style={{ fontSize: '1.2rem' }}>(Included with Product Price)</span>
                                            </div>
                                            <span className={styles.amount}>BDT {formattedPrice(calculatePercentage(order.sub_total, 5))}</span>
                                        </div>
                                        <hr className="print_hr" style={{ marginTop: 0, marginBottom: '1rem' }} /> */}

                                    <div className="print_h_even" style={{marginBottom: "1rem"}}>
                                        <span>Delivery Charge</span>
                                        <span>+ {formattedPrice(order.delivery_charge)}</span>
                                    </div>
                                    <hr className="print_hr" style={{marginTop: 0, marginBottom: "1rem"}}/>
                                    {/*{JSON.parse(order.discounts).map((discount, index) => {*/}
                                    {/*    return (*/}
                                    {/*        <div key={index}>*/}
                                    {/*            <div className="print_h_even" style={{ marginBottom: "1rem" }}>*/}
                                    {/*                <span>{discount.name}</span>*/}
                                    {/*                <span>- BDT {formattedPrice(discount.amount)}</span>*/}
                                    {/*            </div>*/}
                                    {/*            <hr className="print_hr" style={{ marginTop: 0, marginBottom: "1rem" }} />*/}
                                    {/*        </div>*/}
                                    {/*    );*/}
                                    {/*})}*/}
                                    {/* <hr className="print_hr" /> */}
                                    <div className="print_h_even">
                                        <span>Order Total</span>
                                        <span style={{
                                            fontWeight: 600,
                                            fontSize: "2rem"
                                        }}>BDT {formattedPrice(order.total)}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <hr className="print_hr"/>

                    <Row style={{marginTop: "2rem"}} gutter={[16, 0]}>
                        <Col span={12}>
                            <div className="fc">
                                <span className="print_s_title">Customer Details</span>
                                <span>
                                    <span style={{fontWeight: 600}}>Name:</span> {order.customer.name}
                                </span>
                                <span>
                                    <span style={{fontWeight: 600}}>Phone Number:</span> {order.customer.phone_number}
                                </span>
                                <span>
                                    <span style={{fontWeight: 600}}>Member Since:</span>{" "}
                                    {moment(order.customer.registration_date).format("MMMM D, YYYY - LT")}
                                </span>
                                <span className={styles.addr}>
                                    <span className={styles.addr} style={{
                                        fontWeight: 600,
                                        textTransform: "capitalize"
                                    }}>Delivery Address:</span> {order.address},{" "} {order.zone}, {order.city}
                                    {/*{order.delivery_address.area}, {order.delivery_address.district}, {order.delivery_address.division} Division*/}
                                </span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="fc v-bottom">
                                <span className="print_s_title">Order Notes</span>
                                {/*<span>{Math.floor(order.total / 50)} Reward Points Given</span>*/}
                                <span>{order.notes}</span>
                                <span>
                                    {
                                        order.delivery_charge === 0 &&
                                        'Free Home Delivery'
                                    }
                                </span>

                            </div>
                        </Col>
                    </Row>

                    <hr className="print_hr"/>

                    {/*<Row gutter={[16, 0]}>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <div className="fc">*/}
                    {/*            <span className="print_s_title">Terms & Conditions</span>*/}
                    {/*            <span>Products are not returnable after delivery man has left your premises.</span>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <div className="fc v-bottom">*/}
                    {/*            <span className="print_s_title">Queries & Complain</span>*/}
                    {/*            <span>Hotline: 02224470422</span>*/}
                    {/*            <span>Key Contact: Shahana Akter</span>*/}
                    {/*            <span>Email: shahana@nexa.store</span>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <div className="print_footer">
                        <span>Thank you for ordering from us!</span>
                        <span>
                            Have a wonderful day and feel free to visit{" "}
                            <span className="print_site_link" style={{color: 'var(--color-red)'}}>www.cbih.com.bd</span> again
                        </span>
                        <span className="print_hashtag" style={{color: 'var(--color-red)'}}>#Your_Trusted_Import_Partner </span>
                        <div className="hv-center">
                            <div className="print_social">
                                <FaFacebookSquare size={20}/>
                                <span>facebook.com/chinabangladeshimporthub</span>
                            </div>
                            <div className="print_social">
                                <FaInstagramSquare size={20}/>
                                <span>instagram.com/chinabangladeshimporthub</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default OrderInvoice;