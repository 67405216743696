import Layout from "../Components/Layout";
import { Row, Col, Form, Input, Button, Tooltip, Select, Modal, message, Image, Spin, Pagination, Popconfirm, Switch } from "antd";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { uploadImage } from "../api/fundamentals";
import axios from "../config/axios";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import { IoRefreshOutline } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";
import { RiCloseCircleLine } from "react-icons/ri";
import { WarningOutlined } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";



const { Option } = Select;
const { TextArea } = Input;

const SubCategories = () => {
    const [allCategories, setAllCategories] = useState([]);
    const [getSubCategoryLoading, setSubCategoryLoading] = useState(true);
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [specifications, setSpecifications] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [refreshSpec, setRefreshSpec] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [spin, setSpin] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [subCategoryCount, setSubCategoryCount] = useState(0);
    const [limit, setLimit] = useState(10);

    // get All Categories for form and render on useEffect
    const getAllCategories = async () => {
        const res = await axios.get("/api/public/categories", {
            withCredentials: true,
        });
        setAllCategories(res.data);
    };

    const getAllSpecifications = async () => {
        setRefreshSpec(true);
        const res = await axios.get("/api/admin/specifications?limit=500", {
            withCredentials: true,
        });
        setSpecifications(res.data.results);
        setRefreshSpec(false);
    };

    useEffect(() => {
        getAllCategories();
        getAllSpecifications();
    }, []);

    // get All Sub Categories From API and render on useEffect
    const getAllSubCategories = async () => {
        setSpin(true);
        const res = await axios.get("/api/public/subcategories", {
            withCredentials: true,
            params: {
                limit: limit,
                page: searchTerm ? 1 : currentPage,
                search_term: searchTerm !== "" ? searchTerm : null,
            },
        });
        setSubCategoryCount(res.data.count);
        setAllSubCategories(res.data.results);
        setSpin(false);
        setSubCategoryLoading(false);
    };
    useEffect(() => {
        getAllSubCategories();
        // eslint-disable-next-line
    }, [refresh, currentPage, limit]);

    // get debounced search results from search term
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllSubCategories();
        }, 300);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [searchTerm]);

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <Layout pageName="SubCategories">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <SubCategoryAddForm
                        getAllSpecifications={getAllSpecifications}
                        refreshSpec={refreshSpec}
                        specifications={specifications}
                        categories={allCategories}
                        setRefresh={setRefresh}
                    />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllSubCategories
                        subcategories={allSubCategories}
                        subCategoryCount={subCategoryCount}
                        categories={allCategories}
                        loading={getSubCategoryLoading}
                        setRefresh={setRefresh}
                        specifications={specifications}
                        setSearchTerm={setSearchTerm}
                        spin={spin}
                        limit={limit}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        handleCurrentPage={handleCurrentPage}
                    />
                </Col>
            </Row>
        </Layout>
    );
};

export default SubCategories;

// sub components
const SubCategoryAddForm = ({ categories, refreshSpec, getAllSpecifications, setRefresh, specifications }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [addSubCategoryLoading, setAddSubCategoryLoading] = useState(false);
    const [form] = Form.useForm();

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    // handle form with  all data
    const handleAddSubCategory = async (values) => {
        console.log(values);
        if (!values.specifications) {
            message.error("Specifications is a required field");
            return;
        }

        if (images.length < 1) {
            message.error("Pleas­e upload at least one featured image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one featured image!");
            return;
        }
        values.image = images[0];

        // hit endpoint with all values
        setAddSubCategoryLoading(true);
        await axios.post("/api/subcategories", values, { withCredentials: true });
        message.success("added new sub category");
        form.resetFields();
        setAddSubCategoryLoading(false);
        setImages([]);
        setRefresh(Math.random());
    };

    return (
        <div className="card-primary">
            <h2 className="primary_h2"> Add New Sub Category </h2>
            <Form name="addNewSubCategory" form={form} onFinish={handleAddSubCategory} layout="vertical">
                <Form.Item label="Sub Category Name" name="name" rules={[{ required: true, message: "Please Add Category Name" }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                        {
                            required: true,
                            message: "Select Category",
                        },
                    ]}
                >
                    <Select placeholder="Select a Category">
                        {categories.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Specifications */}
                <Form.Item
                    name="specifications"
                    label="Select Specifications"
                    tooltip={{
                        title: "Refresh",
                        icon: (
                            <IoRefreshOutline
                                id={refreshSpec && "specifications_refresh_btn_loading"}
                                onClick={getAllSpecifications}
                                size={20}
                                style={{ cursor: "pointer" }}
                            />
                        ),
                    }}
                >
                    <Select
                        placeholder="Select Specifications"
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {specifications.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Sub Category Description" name="description">
                    <TextArea rows={2} />
                </Form.Item>
                <div className="uploadFeaturedImage">
                    <label style={{ fontWeight: 600 }} htmlFor="logo">
                        Featured Image (400 x 400px)
                    </label>
                    <div className="file-upload">
                        <div className="image-upload-wrap">
                            <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                            <div className="drag-text">
                                {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                            </div>
                        </div>
                        {images.length > 0 ? (
                            <div style={{ marginTop: "2rem" }} className="h-center">
                                {images.map((image, index) => {
                                    return <img key={index} width="50%" src={image} alt="logo" />;
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addSubCategoryLoading}>
                        Add New Sub Category
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const ShowAllSubCategories = ({
    subcategories,
    categories,
    loading,
    setRefresh,
    specifications,
    spin,
    setSearchTerm,
    limit,
    setLimit,
    currentPage,
    subCategoryCount,
    handleCurrentPage,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [updateSubCategoryLoading, setUpdateSubCategoryLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleUpdateSubCategory = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e upload at least one logo image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one logo image!");
            return;
        }
        values.image = images[0];

        // hit endpoint with all values
        setUpdateSubCategoryLoading(true);
        await axios.put(`/api/subcategories/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the sub category");
        setUpdateSubCategoryLoading(false);
        setShowModal(false);
        // setRefresh(Math.random());
        window.location.reload();
        setImages([]);
    };

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Sub Categories`;
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // sub category delete function
    const deleteSubCategory = async (id) => {
        try {
            await axios.delete(`/api/admin/subcategories/${id}`, { withCredentials: true });
            message.success("Deleted the Sub Category");
            setRefresh(Math.random());
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        }
    }

    return (
        <div className="card-primary">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <h2 className="primary_h2">All Sub Categories</h2>
                <Input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    placeholder="Search Sub Categories"
                    suffix={<AiOutlineSearch size={20} />}
                    bordered={false}
                    style={{ width: 200 }}
                />
            </div>
            {loading ? (
                <SimpleLoader height="80vh" />
            ) : (
                <Spin spinning={spin} indicator={<SimpleLoader height={"60vh"} />}>
                    <div className="subcategories_table">
                        <table>
                            <thead>
                                <tr className="tr__head">
                                    <th className="th"> ID </th>
                                    <th className="th"> Image </th>
                                    <th className="th"> Name </th>
                                    <th className="th"> Category </th>
                                    <th className="th">Is Visible</th>
                                    <th className="th"> Featured </th>
                                    <th className="th"> Actions </th>
                                </tr>
                            </thead>
                            <tbody >
                                {subcategories?.map((subcategory, index) => (
                                    <SubCategoryItem
                                        subcategory={subcategory}
                                        key={index}
                                        setShowModal={setShowModal}
                                        setModalData={setModalData}
                                        setImages={setImages}
                                        deleteSubCategory={deleteSubCategory}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination__wrapper">
                        <Pagination
                            showTotal={showTotal}
                            onShowSizeChange={onShowSizeChange}
                            size="small"
                            total={subCategoryCount}
                            onChange={handleCurrentPage}
                            pageSize={limit}
                            current={currentPage}
                        />
                    </div>
                </Spin>
            )}

            {/* modal for edit category */}
            {modalData && (
                <Modal
                    open={showModal}
                    onCancel={() => {
                        setShowModal(false);
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20} />}
                    width={600}
                    style={{ top: 20 }}
                    footer={null}
                >
                    <h2 className="primary_h2"> Update Your Sub Category Data </h2>
                    <Form
                        name="addNewSubCategory"
                        onFinish={handleUpdateSubCategory}
                        layout="vertical"
                        initialValues={{
                            ...modalData,
                            category: modalData.category.id
                        }}
                    >
                        <Form.Item label="Sub Category Name" name="name" rules={[{ required: true, message: "Please Add Category Name" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="category"
                            label="Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Category",
                                },
                            ]}
                        >
                            <Select placeholder="Select a Category">
                                {categories.map((option, index) => (
                                    <Option value={option.id} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Specifications */}
                        <Form.Item
                            name="specifications"
                            label="Select Specifications"
                            rules={[{ required: true, message: "Specifications is a required field" }]}
                        >
                            <Select
                                placeholder="Select Specifications"
                                allowClear
                                mode="multiple"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {specifications.map((option, index) => (
                                    <Option value={option.id} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Sub Category Description" name="description">
                            <TextArea rows={2} />
                        </Form.Item>
                        <div className="uploadFeaturedImage">
                            <label style={{ fontWeight: 600 }} htmlFor="logo">
                                Featured Image (400 x 400px)
                            </label>
                            <div className="file-upload">
                                <div className="image-upload-wrap">
                                    <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                    <div className="drag-text">
                                        {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                                    </div>
                                </div>
                                {images.length > 0 ? (
                                    <div style={{ marginTop: "2rem" }} className="h-center">
                                        {images.map((image, index) => {
                                            return <img key={index} width="50%" src={image} alt="logo" />;
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateSubCategoryLoading}>
                                Update Sub Category
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};


const SubCategoryItem = ({ subcategory, setShowModal, setModalData, setImages, deleteSubCategory }) => {
    const [isVisible, setIsVisible] = useState(() => subcategory.is_visible);
    const [isFeatured, setIsFeatured] = useState(() => subcategory.featured);

    useEffect(() => {
        setIsVisible(subcategory.is_visible)
        setIsFeatured(subcategory.featured)
    }, [subcategory]);


    //handle updateSubCategoryVisibility function
    const updateSubCategoryVisibility = async (value) => {
        setIsVisible(value);
        let payload = {
            is_visible: value
        }
        // hit endpoint with all values
        await axios.put(`/api/subcategories/${subcategory.id}`, payload, {
            withCredentials: true,
        });
        message.success("Sub Category visibility updated successfully!");
    }

    // handle update sub category featured option
    const updateSubCategoryFeatured = async (value) => {
        setIsFeatured(value);
        let payload = {
            featured: value
        }
        // hit endpoint with all values
        await axios.put(`/api/subcategories/${subcategory.id}`, payload, {
            withCredentials: true,
        });
        message.success("Sub Category featured option updated successfully!");
    }


    return (
        <tr className="tr">
            <td className="td">
                {subcategory.id}
            </td>
            <td className="td">
                <Image width={65} src={subcategory.image} alt="logo" />
            </td>
            <td className="td"> {subcategory.name} </td>
            <td className="td"> {subcategory.category.name} </td>
            <td className="td">
                <Switch checked={isVisible} onChange={value => updateSubCategoryVisibility(value)} />
            </td>

            <td className="td">
                <Switch checked={isFeatured} onChange={value => updateSubCategoryFeatured(value)} />
            </td>

            <td className="td" style={{ cursor: "pointer", textAlign: "center", minWidth: "7.5rem" }}>
                <Tooltip title="Edit Subcategory" placement="left">
                    <FiEdit
                        className="sh__edit__products-icon"
                        onClick={() => {
                            setModalData(subcategory);
                            setShowModal(true);
                            setImages([subcategory.image]);
                        }}
                        style={{ marginRight: "0.5rem" }}
                    />
                </Tooltip>

                <Popconfirm
                    title="Are You Sure? This Action Can't be Undone."
                    placement="topRight"
                    onConfirm={() => {
                        deleteSubCategory(subcategory.id)
                    }}
                    okText="Confirm"
                    icon={<WarningOutlined style={{ color: 'red' }} />}
                >
                    <MdDelete title="Delete SubCategory"
                        className="sh__edit__products-icon"
                    />
                </Popconfirm>
            </td>
        </tr>
    )
}