import { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import { Row, Col, Form, Input, Button, Tooltip, Modal, message, Spin, Image, Popconfirm, Switch } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { uploadImage } from "../api/fundamentals";
import axios from "../config/axios";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import { RiCloseCircleLine } from "react-icons/ri";
import { WarningOutlined } from '@ant-design/icons';

const {TextArea} = Input;


export default function Categories() {
    const [getCategoryLoading, setGetCategoryLoading] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [spin, setSpin] = useState(false);

    // get All Categories From API
    const getAllCategories = async () => {
        setSpin(true);
        const res = await axios.get("/api/public/categories", {
            withCredentials: true,
        });
        setAllCategories(res.data);
        setSpin(false);
        setGetCategoryLoading(false);
    };

    useEffect(() => {
        getAllCategories();
    }, [refresh]);

    return (
        <Layout pageName="Categories">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <CategoryAddForm setRefresh={setRefresh}/>
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllCategories categories={allCategories} setCategories={setAllCategories}
                                       loading={getCategoryLoading} setRefresh={setRefresh} spin={spin}/>
                </Col>
            </Row>
        </Layout>
    );
}

// sub components
function CategoryAddForm({setRefresh}) {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [addCategoryLoading, setAddCategoryLoading] = useState(false);
    const [form] = Form.useForm();

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData).then((res) => {
                const {response, success} = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleAddCategory = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e upload at least one logo image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one logo image!");
            return;
        }
        values.image = images[0];
        console.log(values);

        // hit endpoint with all values
        setAddCategoryLoading(true);
        await axios.post("/api/categories", values, {
            withCredentials: true,
        });
        message.success("Added New Category!");
        setAddCategoryLoading(false);
        form.resetFields();
        setRefresh(Math.random());
        setAddCategoryLoading(false);
        setImages([]);
    };

    return (
        <div className="card-primary">
            <h2 className="primary_h2"> Add New Category </h2>
            <Form name="addNewCategory" form={form} onFinish={handleAddCategory} layout="vertical" scrollToFirstError>
                <Form.Item label="Category Name" name="name"
                           rules={[{required: true, message: "Please Add Category Name"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Bangla Name" name="bn_name"
                           rules={[{required: true, message: "Please Add Bangla Name"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Category Description" name="description">
                    <TextArea rows={2}/>
                </Form.Item>
                <div className="uploadFeaturedImage">
                    <label style={{fontWeight: 600}} htmlFor="logo">
                        Featured Image (1200 x 450 px)
                    </label>
                    <div className="file-upload">
                        <div className="image-upload-wrap">
                            <input className="file-upload-input" type="file" multiple={false}
                                   onChange={imageUploadHandler}/>
                            <div className="drag-text">
                                {xhr === "image" ? <SimpleLoader height={"159px"}/> :
                                    <h3>Drag and drop or click to add Images</h3>}
                            </div>
                        </div>
                        {images.length > 0 ? (
                            <div style={{marginTop: "2rem"}} className="h-center">
                                {images.map((image, index) => {
                                    return <img key={index} width="50%" src={image} alt="logo"/>;
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{marginTop: 10}} loading={addCategoryLoading}>
                        Add New Category
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


const CategoryItem = ({category, setModalData, setImages, setShowModal, deleteCategory}) => {
    const [isVisible, setIsVisible] = useState(() => category.is_visible);

    useEffect(() => {
        setIsVisible(category.is_visible)
    }, [category]);


    //handle updateCategoryVisibility function
    const updateCategoryVisibility = async (value) => {
        setIsVisible(value);
        let payload = {
            is_visible: value
        }
        // hit endpoint with all values
        await axios.put(`/api/categories/${category.id}`, payload, {
            withCredentials: true,
        });
        message.success("Category visibility updated successfully!");
    }

    return (
        <tr className="tr">
            <td className="td"> {category.id} </td>
            <td className="td">
                <Image width={65} src={category.image} alt="logo"/>
            </td>
            <td className="td"> {category.name} </td>
            <td className="td">
                <Switch checked={isVisible} onChange={value => updateCategoryVisibility(value)}/>
            </td>
            <td className="td" style={{cursor: "pointer", textAlign: "center"}}>
                <Tooltip title="Edit Category" placement="top">
                    <FiEdit
                        className="sh__edit__products-icon"
                        onClick={() => {
                            setModalData(category);
                            setShowModal(true);
                            setImages([category.image]);
                        }}
                        style={{marginRight: "0.5rem"}}
                    />
                </Tooltip>

                <Popconfirm
                    title="Are You Sure? This Action Can't be Undone."
                    placement="topRight"
                    onConfirm={() => {
                        deleteCategory(category.id)
                    }}
                    okText="Confirm"
                    icon={<WarningOutlined style={{color: 'red'}}/>}
                >
                    <MdDelete title="Delete Category"
                              className="sh__edit__products-icon"
                    />
                </Popconfirm>

            </td>
        </tr>
    )
}


function ShowAllCategories({categories, setCategories, loading, setRefresh, setSearchTerm, spin}) {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [updateCategoryLoading, setUpdateCategoryLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 500).then((res) => {
                const {response, success} = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    // handle update category with specified id
    const handleUpdateCategory = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e upload at least one logo image!");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e upload only one logo image!");
            return;
        }
        values.image = images[0];

        // hit endpoint with all values
        setUpdateCategoryLoading(true);
        await axios.put(`/api/categories/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the category");
        setUpdateCategoryLoading(false);
        setShowModal(false);
        // setRefresh(Math.random());
        window.location.reload();
    };


    const deleteCategory = async (id) => {
        try {
            await axios.delete(`/api/admin/categories/${id}`, {withCredentials: true});
            message.success("Deleted the category");
            setRefresh(Math.random());
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        }
    }

    return (
        <div className="card-primary">
            <h2 className="primary_h2">All Categories</h2>
            {loading ? (
                <SimpleLoader height="100%"/>
            ) : (
                <Spin spinning={spin} indicator={<SimpleLoader height="100%"/>}>
                    <div className="categories_table">
                        <table>
                            <thead>
                            <tr className="tr__head">
                                <th className="th">ID</th>
                                <th className="th"> Image</th>
                                <th className="th"> Name</th>
                                <th className="th">Is Visible</th>
                                <th className="th"> Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {categories?.map((category, index) => (
                                <CategoryItem category={category} key={index} setModalData={setModalData}
                                              setImages={setImages} setShowModal={setShowModal}
                                              deleteCategory={deleteCategory}/>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div style={{marginTop: 20}}>
                        <span>Total {categories.length} Categories</span>
                    </div>
                </Spin>
            )}

            {/* modal for edit category */}
            {modalData && (
                <Modal
                    visible={showModal}
                    onCancel={() => {
                        setShowModal(false);
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20}/>}
                    width={600}
                    style={{top: 20}}
                    footer={null}
                >
                    <h2 className="primary_h2"> Update Your Category Data </h2>
                    <Form
                        name="addNewCategory"
                        onFinish={handleUpdateCategory}
                        layout="vertical"
                        initialValues={{
                            ...modalData,
                        }}
                    >
                        <Form.Item label="Category Name" name="name"
                                   rules={[{required: true, message: "Please Add Category Name"}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Bangla Name" name="bn_name"
                                   rules={[{required: true, message: "Please Add Bangla Name"}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Category Description" name="description">
                            <TextArea rows={2}/>
                        </Form.Item>
                        <div className="uploadFeaturedImage">
                            <label style={{fontWeight: 600}} htmlFor="logo">
                                Featured Image (1200 x 450 px)
                            </label>
                            <div className="file-upload">
                                <div className="image-upload-wrap">
                                    <input className="file-upload-input" type="file" multiple={false}
                                           onChange={imageUploadHandler}/>
                                    <div className="drag-text">
                                        {xhr === "image" ? <SimpleLoader height={"159px"}/> :
                                            <h3>Drag and drop or click to add Images</h3>}
                                    </div>
                                </div>
                                {images.length > 0 ? (
                                    <div style={{marginTop: "2rem"}} className="h-center">
                                        {images.map((image, index) => {
                                            return <img key={index} width="50%" src={image} alt="logo"/>;
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{marginTop: 10}}
                                    loading={updateCategoryLoading}>
                                Update Category
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
}

