import { useState } from "react";
import Layout from "../Components/Layout";
import { Form, Input, Button, message } from "antd";
import axios from "../config/axios";
const { TextArea } = Input;

const PushNotification = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // send push notification function
    const sendPushNotification = async (values) => {
        // front end verification if admin has permission to send push notifications
        if (values.password !== process.env.REACT_APP_PUSH_NOTIFICATION_SECRET_KEY) {
            message.error("Unauthorized Attempt!");
            return;
        }
        delete values.password;

        // send push notification to users
        setLoading(true);
        const res = await axios.post("/api/communications/send-push-notification", values, { withCredentials: true });
        console.log(res.data);
        message.success("Push Notification sent successfully");
        setLoading(false);
        form.resetFields();
    };
    return (
        <Layout pageName="Push Notification">
            <div className="p_notification">
                <h2 className="primary_h2"> Send Push Notification </h2>
                <Form name="sendPushNotification" form={form} onFinish={sendPushNotification} layout="vertical" scrollToFirstError>
                    <Form.Item label="Push Notification Title" name="title" rules={[{ required: true, message: "Please Add Notification Title" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Push Notification Body" name="body" rules={[{ required: true, message: "Add  Notification Body" }]}>
                        <TextArea rows={2} />
                    </Form.Item>

                    <Form.Item label="Secret Admin Password" name="password" rules={[{ required: true, message: "Enter Your Secret Token" }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={loading}>
                            Send Push Notification
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

export default PushNotification;
