import { useState, useEffect } from 'react';
import { message, Avatar, Switch, Skeleton } from 'antd';
import moment from 'moment';

// importing styles
import styles from './MerchantDetails.module.scss';

// importing components
import Layout from '../../Components/Layout';
import SimpleLoader from '../../Components/SimpleLoader/SimpleLoader';

// importing axios
import axios from '../../config/axios';

const MerchantDetails = ({ match }) => {
    const [merchantDetails, setMerchantDetails] = useState({});
    const [merchantDetailsLoading, setMerchantDetailsLoading] = useState(true);
    const [merchantActiveStatusLoading, setMerchantActiveStatusLoading] = useState(false);

    // get merchant details by param id and render on first load
    useEffect(() => {
        (async () => {
            const res = await axios.get(`/api/admin/merchants/${match.params.id}`, {
                withCredentials: true,
            });
            setMerchantDetails(res.data);
            setMerchantDetailsLoading(false);
        })();
    }, [match.params.id]);

    // handle active-inactive controller switch value change, access admin
    const handleSwitchClick = async (checked) => {
        setMerchantActiveStatusLoading(true);
        await axios.patch(`/api/admin/merchants/${match.params.id}`, { is_active: checked }, { withCredentials: true });
        message.success('Changed active status of Merchant');
        setMerchantActiveStatusLoading(false);
    };

    return (
        <Layout pageName={merchantDetailsLoading ? <Skeleton.Input style={{ width: 150 }} active size='small' /> : <span> {merchantDetails.venture_name} </span>}>
            <div className={styles.merchantDetails}>
                <h2>Details of Merchant - {merchantDetailsLoading ? <Skeleton.Input style={{ width: 150 }} active /> : <span> {merchantDetails.venture_name} </span>} </h2>
                {merchantDetailsLoading ? (
                    <SimpleLoader height='100%' />
                ) : (
                    <div className={styles.details}>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> ID: </span>
                            <span className={styles.details_content}> {merchantDetails.id} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Venture Name: </span>
                            <span className={styles.details_content}> {merchantDetails.venture_name} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Representative Name: </span>
                            <span className={styles.details_content}> {merchantDetails.user_account.name} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Representative Phone Number: </span>
                            <span className={styles.details_content}>
                                {' '}
                                <a href={`tel:${merchantDetails.user_account.phone_number}`}>{merchantDetails.user_account.phone_number}</a>{' '}
                            </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Registration Date: </span>
                            <span className={styles.details_content}> {moment(merchantDetails.user_account.registration_date).format('DD-MM-YYYY, LT')} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Division: </span>
                            <span className={styles.details_content}> {merchantDetails.division} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> District: </span>
                            <span className={styles.details_content}> {merchantDetails.district} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Sub District: </span>
                            <span className={styles.details_content}> {merchantDetails.sub_district} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Address: </span>
                            <span className={styles.details_content}> {merchantDetails.address} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Contact Number: </span>
                            <span className={styles.details_content}>
                                <a href={`tel:${merchantDetails.contact_number}`}>{merchantDetails.contact_number}</a>
                            </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Logo: </span>
                            <span className={styles.details_content}>
                                <Avatar src={merchantDetails.logo} alt='logo' size={75} shape='square' />
                            </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> NID: </span>
                            <span className={styles.details_content}> {merchantDetails.nid} </span>
                        </div>
                        <div className={styles.details_row}>
                            <span className={styles.details_title}> Active Status: </span>
                            <span className={styles.details_content}>
                                {' '}
                                <Switch defaultChecked={merchantDetails.is_active} onChange={handleSwitchClick} loading={merchantActiveStatusLoading} />{' '}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default MerchantDetails;
