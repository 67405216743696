import { Link, useHistory } from "react-router-dom";
import { useState } from 'react';

// importing components
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import Layout from "../../../Components/Layout";
import SimpleLoader from "../../../Components/SimpleLoader/SimpleLoader";
import ProductDescriptionEditor from "../../../Components/Products/ProductDescriptionEditor";

// importing styles
import styles from "./CreateBlog.module.scss";

// importing icons
import { ArrowLeftOutlined } from '@ant-design/icons';

// importing fundamentals
import { uploadImage } from "../../../api/fundamentals";
import axios from "../../../config/axios";


const CreateBlog = () => {
    const history = useHistory();
    const [body, setBody] = useState("");
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [publishBlogLoading, setPublishBlogLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 750, 250).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const createNewBlog = async (values) => {

        if (images.length < 1) {
            message.error("Upload Featured Image");
            return;
        }
        if (images.length > 1) {
            message.error("Upload Only One Featured Image!");
            return;
        }

        if (!body) {
            message.error("Product description is required!");
            return;
        }

        values.featured_image = images[0];
        values.tags = values.tags.join(",");
        values.body = body;

        setPublishBlogLoading(true)
        await axios.post("/api/blogs", values, { withCredentials: true })
        message.success("Published New Blog")
        setPublishBlogLoading(false)

        history.push("/blogs")
    }

    return (
        <Layout pageName="Create Blog">
            <div className="card">
                <div className={styles.create_blog_top}>
                    <h2 className="primary_h2"> Create New Blog</h2>
                    <Link to="/blogs">
                        <Button type="primary" icon={<ArrowLeftOutlined />}>
                            Back To Blogs
                        </Button>
                    </Link>
                </div>

                <div className={styles.create_blog_form}>
                    <Form
                        name="dynamic_form_nest_item"
                        onFinish={createNewBlog}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError

                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <div className="uploadLogo">
                                    <label style={{ fontWeight: 600 }} htmlFor="logo">
                                        Featured Image
                                    </label>
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                            <div className="drag-text">
                                                {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                                            </div>
                                        </div>
                                        {images.length > 0 ? (
                                            <div style={{ marginTop: "2rem" }} className="h-center">
                                                {images.map((image, index) => {
                                                    return <img
                                                        key={index}
                                                        className={styles.featured_image}
                                                        src={image}
                                                        alt="featured_image"
                                                    />;
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="title"
                                    label="Blog Title"
                                    rules={[{ required: true, message: "Add A Blog Title" }]}

                                >
                                    <Input
                                        placeholder="How To Buy From Nexa"
                                        maxLength={245}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Meta Description for SEO"
                                    name="meta_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Add a Meta Description for SEO',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        showCount
                                        maxLength={155}
                                        rows={2}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Blog Body"
                                >
                                    <ProductDescriptionEditor
                                        description={body}
                                        setDescription={setBody}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="tags"
                                    label="Tags (Type Appropriate Tags in Lowercase &amp; Press Enter.)"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Add At Least One Tag",
                                        },
                                    ]}
                                >
                                    <Select placeholder="tag_1,tag_2" mode="tags">
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        loading={publishBlogLoading}
                                        size="medium"
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Publish New Blog
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Layout>
    )
}

export default CreateBlog;