import axios from '../config/axios';

//integrating upload image endpoint
export const uploadImage = (payload, width = null, height = null) => {

    return axios.post('/api/fundamentals/upload-image', payload, {
        withCredentials: true,
        params: {
            width: width,
            height: height
        }
    }).then(response => {
        return {
            success: true,
            response: response
        }
    });
}

export const uploadVideo = (payload, width = null, height = null) => {

    return axios.post('/api/fundamentals/upload-video', payload, {
        withCredentials: true,
        params: {
            width: width,
            height: height
        }
    }).then(response => {
        return {
            success: true,
            response: response
        }
    });
}