import { ConfigProvider } from "antd";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import "./index.css";
import colors from "./styles/colors";
//importing all components
import Landing from "./Views/Landing/Landing";
import Login from "./Views/Login";
// import Register from "./Views/Register";
import CreateCatalogue from "./Components/Catalogue/CreateCatalogue";
import ViewAllCatalogue from "./Components/Catalogue/ViewAllCatalogue";
import PrivateRoute from "./HOComponents/PrivateRoute";
import Brands from "./Views/Brands";
import Categories from "./Views/Categories";
import CreateTransaction from "./Views/CreateTransaction";
import Customers from "./Views/Customers/Customers";
import EditProduct from "./Views/EditProduct";
import MerchantDetails from "./Views/MerchantDetails/MerchantDetails";
import Merchants from "./Views/Merchants/Merchants";
import OrderDetails from "./Views/OrderDetails/OrderDetails";
import OrderItems from "./Views/OrderItems/OrderItems";
import Orders from "./Views/Orders/Orders";
import PaymentSettlements from "./Views/PaymentSettlements";
import ProductSpecification from "./Views/ProductSpecification";
import Products from "./Views/Products";
import Shop from "./Views/Shop";
import SubCategories from "./Views/SubCategories";
import SubSubcategories from "./Views/SubSubcategories";
// import EmptyPage from "./Views/EmptyPage";
import TierOneRoute from "./HOComponents/TierOneRoute";
import Attendance from "./Views/Attendance/Attendance";
import B2BOrders from "./Views/B2BOrders";
import AllBlogs from "./Views/Blogs/AllBlogs/AllBlogs";
import CreateBlog from "./Views/Blogs/CreateBlog/CreateBlog";
import EditBlog from "./Views/Blogs/EditBlog/EditBlog";
import Calculator from "./Views/Calculator/Calculator";
import CreateCustomer from "./Views/CreateCustomer/CreateCustomer";
import CreateOrder from "./Views/CreateOrder/CreateOrder";
import ExcelToJson from "./Views/ExcelToJson/ExcelToJson";
import ExportProductsToExcel from "./Views/ExportProductToExcel/ExportProductsToExcel";
import CreateEmployee from "./Views/HR/CreateEmployee";
import Employees from "./Views/HR/Employees";
import OrderInvoice from "./Views/OrderInvoice/OrderInvoice";
import BarcodePrint from "./Views/Products/BarcodePrint";
import CreateProduct from "./Views/Products/CreateProduct";
import PushNotification from "./Views/PushNotification";
import PushNotificationTokens from "./Views/PushNotificationTokens";
import SendSms from "./Views/SendSms/SendSms";
import Settings from "./Views/Settings/Settings";
import Racks from "./Views/Warehouses/Racks/Racks";
import AllStockIn from "./Views/Warehouses/StockIn/AllStockIn";
import StockIn from "./Views/Warehouses/StockIn/StockIn";
import AllStockOut from "./Views/Warehouses/StockOut/AllStockOut";
import StockOut from "./Views/Warehouses/StockOut/StockOut";
import SubRacks from "./Views/Warehouses/SubRacks/SubRacks";
import Warehouse from "./Views/Warehouses/Warehouse/Warehouse";
import Zones from "./Views/Zones";

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: colors.primary,
                    colorPrimaryActive: colors.active,
                    colorPrimaryBg: colors.active,
                },
                components: {
                    Button: {
                        colorPrimary: colors.primary,
                    },
                    Select: {},
                },
            }}
        >
            <Router>
                <Route path="/login" component={Login} exact />
                <Switch>
                    <PrivateRoute roles={["admin"]} path="/" component={Landing} exact />
                    <PrivateRoute roles={["admin"]} path="/shop" component={Shop} exact />
                    <PrivateRoute roles={["admin"]} path="/create-product" component={CreateProduct} exact />
                    <PrivateRoute roles={["admin"]} path="/create-catalogue" component={CreateCatalogue} exact />
                    <PrivateRoute roles={["admin"]} path="/view-all-catalogue" component={ViewAllCatalogue} exact />
                    <PrivateRoute roles={["admin"]} path="/orders" component={Orders} exact />
                    <PrivateRoute roles={["admin"]} path="/b2b-orders" component={B2BOrders} exact />
                    <PrivateRoute roles={["admin"]} path="/orders/:id" component={OrderDetails} exact />
                    <PrivateRoute roles={["admin"]} path="/create-order" component={CreateOrder} exact />
                    <PrivateRoute roles={["admin"]} path="/invoice/:id" component={OrderInvoice} exact />
                    <PrivateRoute roles={["admin"]} path="/payments" component={PaymentSettlements} exact />
                    <PrivateRoute roles={["admin"]} path="/settings" component={Settings} exact />
                    <PrivateRoute roles={["admin"]} path="/product-specification" component={ProductSpecification} exact />
                    <PrivateRoute roles={["admin"]} path="/categories" component={Categories} exact />
                    <PrivateRoute roles={["admin"]} path="/sub-categories" component={SubCategories} exact />
                    <PrivateRoute roles={["admin"]} path="/sub-subcategories" component={SubSubcategories} exact />
                    <PrivateRoute roles={["admin"]} path="/brands" component={Brands} exact />
                    <PrivateRoute roles={["admin"]} path="/merchants" component={Merchants} exact />
                    <PrivateRoute roles={["admin"]} path="/merchants/:id" component={MerchantDetails} exact />
                    <PrivateRoute roles={["admin"]} path="/order-items" component={OrderItems} exact />
                    <PrivateRoute roles={["admin"]} path="/customers" component={Customers} exact />
                    <PrivateRoute roles={["admin"]} path="/create-customer" component={CreateCustomer} exact />
                    <PrivateRoute roles={["admin"]} path="/create-transaction" component={CreateTransaction} exact />
                    <PrivateRoute roles={["admin"]} path="/products" component={Products} exact />
                    <PrivateRoute roles={["admin"]} path="/products/edit-product/:productID" component={EditProduct} exact />
                    <PrivateRoute roles={["admin"]} path="/products/barcode-print/:productID" component={BarcodePrint} exact />
                    <PrivateRoute roles={["admin"]} path="/push-notification" component={PushNotification} exact />
                    <PrivateRoute roles={["admin"]} path="/areas" component={Zones} exact />
                    <PrivateRoute roles={["admin"]} path="/products/export" component={ExportProductsToExcel} exact />
                    <PrivateRoute roles={["admin"]} path="/calculator" component={Calculator} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses" component={Warehouse} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/racks" component={Racks} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/sub-racks" component={SubRacks} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/stock-in" component={AllStockIn} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/stock-out" component={AllStockOut} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/create-stock-in" component={StockIn} exact />
                    <PrivateRoute roles={["admin"]} path="/warehouses/create-stock-out" component={StockOut} exact />
                    <PrivateRoute roles={["admin"]} path="/hr" component={Employees} exact />
                    <PrivateRoute roles={["admin"]} path="/hr/create-new-employee" component={CreateEmployee} exact />
                    <PrivateRoute roles={["admin"]} path="/attendance" component={Attendance} exact />
                    <PrivateRoute roles={["admin"]} path="/blogs" component={AllBlogs} exact />
                    <PrivateRoute roles={["admin"]} path="/blogs/create-blog" component={CreateBlog} exact />
                    <PrivateRoute roles={["admin"]} path="/blogs/edit-blog/:id" component={EditBlog} exact />
                    <PrivateRoute roles={["admin"]} path="/excel-to-json" component={ExcelToJson} exact />
                    <TierOneRoute path="/push-notification-tokens" component={PushNotificationTokens} exact />
                    <TierOneRoute path="/send-sms" component={SendSms} exact />
                </Switch>
                {/* handling 404 error page */}
                {/* <Route path="*" component={EmptyPage} exact /> */}
            </Router>
        </ConfigProvider>
    );
}

export default App;
