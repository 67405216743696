import Layout from "../Components/Layout";
import React, { useState, useEffect } from "react";
import { Select, Checkbox, Divider } from "antd";
import axios from "../config/axios";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";

const CreateTransaction = () => {
    const [searchTerm, setSearchTerm] = useState(null);
    const [allMerchant, setAllMerchant] = useState([]);
    const [paymentAccounts, setPaymentAccounts] = useState([]);
    const [paymentAccountsLoading, setPaymentAccountsLoading] = useState(false);

    const [unsettledPaymentsOptions, setUnsettledPaymentsOptions] = useState([]);
    const [checkboxLoading, setCheckboxLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < unsettledPaymentsOptions.length);
        setCheckAll(list.length === unsettledPaymentsOptions.length);
        console.log(list);
        setTotalPrice(list.reduce((a, b) => a + b, 0));
    };

    const onCheckAllChange = (e) => {
        console.log(unsettledPaymentsOptions);
        setCheckedList(e.target.checked ? unsettledPaymentsOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    //all product search debounce function
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get(`/api/admin/merchants`, { withCredentials: true, params: { search_term: searchTerm ?? null } }).then((response) => {
                setAllMerchant(response.data.results);
            });
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const handleMerchantSelection = async (value, { id }) => {
        // get unsettledPayments for selected merchant
        setCheckboxLoading(true);
        const res = await axios.get("api/admin/orders/order-items", {
            withCredentials: true,
            params: {
                merchant_slug: value,
            },
        });
        const checkboxData = res.data.results.map((item) => {
            return { label: `${item.name} - ${item.order} - ${item.quantity} - ${item.order_time_price}`, value: item.order_time_price };
        });
        console.log(checkboxData);
        setUnsettledPaymentsOptions(checkboxData);
        setCheckboxLoading(false);

        // get payment accounts for selected merchant
        setPaymentAccountsLoading(true);
        const response = await axios.get("api/payments/merchants/payment-accounts", { withCredentials: true, params: { merchant_id: id } });
        setPaymentAccounts(response.data);
        setPaymentAccountsLoading(false);
    };

    return (
        <Layout pageName="Create Transaction">
            <div className="create_transaction">
                <h2 className="primary_h2"> Create Transaction </h2>
                <div>
                    <Select
                        placeholder="Search for Merchants"
                        showSearch
                        onSearch={(value) => {
                            setSearchTerm(value);
                        }}
                        filterOption={false}
                        showArrow={false}
                        onChange={handleMerchantSelection}
                        style={{ width: "100%" }}
                    >
                        {allMerchant?.map((merchant, index) => {
                            return (
                                <Select.Option value={merchant.slug} id={merchant.id} key={index}>
                                    {merchant.venture_name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                {checkboxLoading ? (
                    <SimpleLoader height="40vh" />
                ) : (
                    <>
                        {unsettledPaymentsOptions.length > 0 && (
                            <>
                                <div className="create_transaction_checkbox">
                                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                        Check all
                                    </Checkbox>
                                    <Divider style={{ margin: "10px 0" }} />

                                    <Checkbox.Group style={{ display: "flex", flexDirection: "column" }} options={unsettledPaymentsOptions} value={checkedList} onChange={onChange} />
                                </div>
                                <div>total price {totalPrice}</div>
                                <Select
                                    onChange={(value) => {
                                        console.log(value);
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder="Select Payment Accounts"
                                    loading={paymentAccountsLoading}
                                >
                                    {paymentAccounts?.map((account, index) => {
                                        return (
                                            <Select.Option key={index} value={account.number}>
                                                {`${account.number} - ${account.type}`}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </>
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
};

export default CreateTransaction;
