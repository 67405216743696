import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../config/axios";

//importing components
import { Button, Col, Form, Image, Input, Modal, Radio, Row, Select, Space, message } from "antd";
import ProductDescriptionEditor from "../Products/ProductDescriptionEditor";

//importing icons
import { AiFillCloseCircle, AiOutlineMinusCircle } from "react-icons/ai";

//importing api services
import { uploadImage, uploadVideo } from "../../api/fundamentals";

//importing utils
import Loader from "../../Components/Library/Loader";
import ReorderImages from "./ReorderImages";

export default function CreateProductForm() {
    const [variantForm] = Form.useForm();
    const { Option } = Select;
    const history = useHistory();

    const [images, setImages] = useState([]);
    const [video, setVideo] = useState(null);
    const [sizeChart, setSizeChart] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [featuredImage, setFeaturedImage] = useState("");
    const [openModalForVariantImage, setOpenModalForVariantImage] = useState(false);

    // handle product category and category slug
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);

    // handle product sub category and sub category slug
    const [productSubCategoryOptions, setProductSubCategoryOptions] = useState([]);

    // handle product brand
    const [productBrandOptions, setProductBrandOptions] = useState([]);

    const [variants, setVariants] = useState([]);
    const [productSpecifications, setProductSpecifications] = useState([]);

    const imageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 1000, 1000).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages((images) => images.concat(response.data.secure_url));
                    setXhr(false);
                }
            });
        }
    };

    const featuredImageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 800, 800).then((res) => {
                const { response, success } = res;
                if (success) {
                    setFeaturedImage(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    const videoUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("video", e.target.files[i]);

            uploadVideo(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setVideo(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    const sizeChartUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 1000, 1000).then((res) => {
                const { response, success } = res;
                if (success) {
                    setSizeChart(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    //   get product category options
    const getProductCategories = async () => {
        const res = await axios.get(
            "/api/public/categories",
            { withCredentials: true },
            {
                params: {
                    public: null,
                },
            }
        );
        setProductCategoryOptions(res.data);
    };

    //   get product sub category options
    const getProductSubCategories = async (category) => {
        const res = await axios.get(`/api/public/subcategories?category=${category}&serialize=true`, {
            withCredentials: true,
        });
        setProductSubCategoryOptions(res.data);
    };

    //   get brands
    const getProductBrands = async () => {
        const res = await axios.get("/api/public/brands?limit=500", {
            withCredentials: true,
        });
        setProductBrandOptions(res.data.results);
    };

    //   handle category value change
    const handleCategoryChange = (value) => {
        getProductSubCategories(value);
    };

    //   handle sub category value change
    const handleSubCategoryChange = (value, { index }) => {
        setProductSpecifications(productSubCategoryOptions[index].specifications);
    };

    //   get category, subcategory and brands on useEffect
    useEffect(() => {
        getProductCategories();
        getProductBrands();
    }, []);

    // update image reordering
    const updateImages = (images) => {
        setImages(images);
    };

    const addVariantHandler = () => {
        const variant = variantForm.getFieldsValue();
        if (!variant.size) {
            message.error("Variant Size is required!");
            return;
        }
        if (!variant.stock) {
            message.error("Variant Stock is required!");
            return;
        }

        let dup_variants = variants.filter((vari) => vari.size.toLowerCase() === variant.size.toLowerCase());
        if (dup_variants.length !== 0) {
            message.error("Duplicate Size");
            return;
        }

        variantForm.resetFields();
        setVariants((variants) => variants.concat(variant));
    };

    const onFinish = async (values) => {
        try {
            if (!featuredImage) {
                message.error("Please upload a featured image!");
                return;
            }

            if (images.length === 0) {
                message.error("Upload at least 1 product image!");
                return;
            }

            if (images.length > 6) {
                message.error("Too Many product image!");
                return;
            }

            if (!description) {
                message.error("Product description is required!");
                return;
            }

            if (variants.length < 1) {
                message.error("Add minimum 1 variant!");
                return;
            }

            values.featured_image = featuredImage;

            setLoading(true);

            // handle custom inputs
            values.size_chart = sizeChart;
            values.variants = variants;
            values.images = images;
            values.video = video;
            values.description = description;
            values.net_weight = values.net_weight ? parseFloat(values.net_weight).toFixed(2) : null;
            values.gross_weight = values.gross_weight ? parseFloat(values.gross_weight).toFixed(2) : null;
            if (!values.discounted_price) {
                values.discounted_price = values.regular_price;
            }

            console.log(values);

            await axios.post("/api/products", values, {
                withCredentials: true,
            });
            message.success("New product was created!");

            history.push("/products");
        } catch (error) {
            message.error(error?.response?.data?.msg || "Something went wrong!");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card" style={{ marginTop: "4rem" }}>
            <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" layout="vertical" scrollToFirstError>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item name="name" label="Product Name" rules={[{ required: true, message: "Provide a valid name" }]} required>
                            <Input type="text" />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <label style={{ fontWeight: 600 }} htmlFor="">
                            Product Featured Image
                        </label>
                        <div className="file-upload">
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type="file" multiple={false} onChange={featuredImageUploadHandler} />
                                <div className="drag-text">
                                    {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Image</h3>}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        {featuredImage && <img src={featuredImage} style={{ width: "100%" }} className={"mt-5"} alt="featured_image" />}
                    </Col>
                    <Col span={16}>
                        <label style={{ fontWeight: 600 }} htmlFor="">
                            Product Video (Optional)
                        </label>
                        <div className="file-upload">
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type="file" multiple={false} onChange={videoUploadHandler} />
                                <div className="drag-text">
                                    {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Video</h3>}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        {video && (
                            <div className="position-relative ">
                                <video
                                    controls={true}
                                    n
                                    src={video}
                                    autoPlay={true}
                                    muted={true}
                                    style={{ width: "100%", height: 300 }}
                                    className={"mt-5"}
                                    alt="video"
                                />

                                <div
                                    style={{
                                        position: "absolute",
                                        top: 5,
                                        right: 5,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setVideo(null);
                                    }}
                                >
                                    <AiFillCloseCircle size={20} color={"var(--color-red)"} />
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col span={24}>
                        <label style={{ fontWeight: 600 }} htmlFor="">
                            Product Gallery
                        </label>
                        <div className="file-upload">
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type="file" multiple={true} onChange={imageUploadHandler} />
                                <div className="drag-text">
                                    {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                                </div>
                            </div>

                            {images.length > 0 ? (
                                <div style={{ marginTop: "2rem" }}>
                                    <ReorderImages images={images} callback={updateImages} />
                                </div>
                            ) : null}
                        </div>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="color"
                            label="Product Color"
                            rules={[{ required: true, message: "Provide a valid color" }]}
                            required
                        >
                            <Input type="text" placeholder="black" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="pxp" label="PXP" rules={[{ required: true, message: "Provide a valid pxp" }]} required>
                            <Input type="number" placeholder="xxxx" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="regular_price"
                            label="Regular Price"
                            rules={[{ required: true, message: "Enter product price" }]}
                            required
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="discounted_price" label="Discounted Price (If Applicable)">
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="category"
                            label="Product Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Product Category",
                                },
                            ]}
                        >
                            <Select placeholder="Select a Category" onChange={handleCategoryChange}>
                                {productCategoryOptions.map((option, index) => (
                                    <Option value={option.id} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="subcategory"
                            label="Product Sub Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Product Sub-Category",
                                },
                            ]}
                        >
                            <Select placeholder="Select a Sub-Category" onChange={handleSubCategoryChange}>
                                {productSubCategoryOptions.map((option, index) => (
                                    <Option value={option.id} key={index} index={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="brand"
                            label="Product Brand"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select product brand or set as Other",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a Brand"
                                allowClear
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {productBrandOptions?.map((option, index) => (
                                    <Option value={option.id} key={index} name={option.name}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={16}>
                        <label style={{ fontWeight: 600 }} htmlFor="">
                            Size Chart
                        </label>
                        <div className="file-upload">
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type="file" multiple={false} onChange={sizeChartUploadHandler} />
                                <div className="drag-text">
                                    {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Image</h3>}
                                </div>
                            </div>

                            {sizeChart ? (
                                <div style={{ marginTop: "2rem" }}>
                                    <img src={sizeChart} alt="" />
                                </div>
                            ) : null}
                        </div>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="country_of_origin"
                            label="Country Of Origin"
                            rules={[{ required: true, message: "Provide Country Of Origin" }]}
                            required
                        >
                            <Input type="text" placeholder="Bangladesh" />
                        </Form.Item>
                    </Col>

                    {/*<Col span={8}>*/}
                    {/*    <Form.Item name="net_weight" label="Product Net Weight (in KG)">*/}
                    {/*        <Input type="number" maxLength="9" step="any" placeholder="10"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    {/*<Col span={8}>*/}
                    {/*    <Form.Item name="gross_weight" label="Product Gross Weight (in KG)">*/}
                    {/*        <Input type="number" maxLength="9" step="any" placeholder="10"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    <Col span={24}>
                        <Form.Item
                            label="Meta Description for SEO"
                            name="meta_description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Add a Meta Description for SEO",
                                },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={155} rows={2} />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <label htmlFor="">Product Description</label> */}
                <Form.Item name="description" label="Product Description">
                    <ProductDescriptionEditor description={description} setDescription={setDescription} />
                </Form.Item>

                <br />
                <label htmlFor="">Product Variants</label>
                <Form form={variantForm} name={"variant_form"}>
                    <Row gutter={[20, 20]}>
                        <Col span={4}>
                            <Form.Item name={"size"} label={"Size"} required={true}>
                                <Input placeholder={"XL"} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={"stock"} label={"Stock"} required={true}>
                                <Input placeholder={"25"} type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={"price"} label={"Price (Optional)"} required={false}>
                                <Input placeholder={"25"} type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Image (Optional)" name="image">
                                <Button
                                    onClick={() => {
                                        setOpenModalForVariantImage(true);
                                    }}
                                    className="me-3"
                                >
                                    Select Image
                                </Button>

                                {variantForm.getFieldsValue().image && (
                                    <img
                                        src={variantForm.getFieldsValue().image}
                                        alt="variant"
                                        style={{
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={"Action"}>
                                <Button type={"primary"} onClick={addVariantHandler}>
                                    Add Variant
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div style={{ maxWidth: 700, border: "1px solid var(--color-grey-light-3)" }} className={"mt-5"}>
                    <table>
                        <tr>
                            <th style={{ textAlign: "left" }}>Size</th>
                            <th style={{ textAlign: "left" }}>Stock</th>
                            <th style={{ textAlign: "left" }}>Price</th>
                            <th style={{ textAlign: "left" }}>Image</th>
                            <th style={{ textAlign: "left" }}>Actions</th>
                        </tr>
                        {variants.map((variant, index) => {
                            return (
                                <tr key={index}>
                                    <td>{variant.size}</td>
                                    <td>{variant.stock}</td>
                                    <td>{variant.price} </td>
                                    <td>
                                        <Image width={60} src={variant.image} height={60} fallback="/no_image.png" />
                                    </td>
                                    <td>
                                        <Button
                                            danger={true}
                                            onClick={() => {
                                                let updated_variants = variants.filter((vari) => vari.size !== variant.size);
                                                setVariants(updated_variants);
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>

                <br />
                <label htmlFor="">Product Specifications (Max 35 Characters)</label>

                {/* 🔥 Specifications */}
                {productSpecifications.length !== 0 ? (
                    <Form.List name="specifications" initialValue={productSpecifications}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey }, index) => (
                                    <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            initialValue={productSpecifications[index].name}
                                            name={[name, "name"]}
                                            fieldKey={[fieldKey, "name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Missing specification name",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Specification Name" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            name={[name, "value"]}
                                            fieldKey={[fieldKey, "value"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Missing specification value",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Specification Value" maxLength={35} />
                                        </Form.Item>
                                        <AiOutlineMinusCircle style={{ cursor: "pointer" }} onClick={() => remove(name)} />
                                    </Space>
                                ))}
                            </>
                        )}
                    </Form.List>
                ) : null}

                <br />
                <Form.Item>
                    <Button loading={loading} disabled={loading} size="medium" style={{ width: "100%" }} type="primary" htmlType="submit">
                        Create New Product
                    </Button>
                </Form.Item>
            </Form>

            <SelectImageFromModal
                open={openModalForVariantImage}
                close={() => {
                    setOpenModalForVariantImage(false);
                }}
                images={images}
                variantForm={variantForm}
            />
        </div>
    );
}

const SelectImageFromModal = ({ open, close, images, variantForm }) => {
    const [value, setValue] = useState(null);

    const onChange = (e) => {
        setValue(e.target.value);
        variantForm.setFieldsValue({
            image: e.target.value,
        });
        close();
    };

    return (
        <Modal title={`Select Image`} open={open} onCancel={close} footer={null} centered destroyOnClose>
            {images.length === 0 && <p>Upload at least 1 image in gallery.</p>}

            <Radio.Group onChange={onChange} value={value} style={{ marginTop: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 10,
                    }}
                >
                    {images.map((image, index) => (
                        <Radio value={image} key={index}>
                            <Image src={image} preview={false} height={100} width={100} />
                        </Radio>
                    ))}
                </div>
            </Radio.Group>
        </Modal>
    );
};
