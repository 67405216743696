import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from "../../config/axios";

// importing components
import Layout from "../../Components/Layout";
import { Button, Image, Modal, Tooltip, Form, Input, Select, message } from "antd";
import NoData from "../../Components/Library/NoData";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";

// importing icons
import { FiEdit } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";

// importing helpers
import { uploadImage } from "../../api/fundamentals";


const { Option } = Select;



const Employees = () => {

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [allEmployee, setAllEmployee] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [updateEmployeeLoading, setUpdateEmployeeLoading] = useState(false);

    const getAllEmployee = async () => {
        const res = await axios.get('/api/employees', { withCredentials: true })
        setAllEmployee(res.data)
        setLoading(false)
    }

    useEffect(() => {
        getAllEmployee()
    }, []);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleUpdateEmployeeProfile = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e Upload Profile Picture");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e Upload Only One Image!");
            return;
        }

        values.dp = images[0];

        setUpdateEmployeeLoading(true)
        await axios.patch(`/api/employees/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated Employee Profile")
        setUpdateEmployeeLoading(false)
        setShowModal(false)
        window.location.reload()

    }


    return (
        <Layout pageName="Human Resource">
            <div className="card">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <h2 className="primary_h2"> Employee Management</h2>
                    <Button type="primary" onClick={() => history.push("/hr/create-new-employee")}>
                        Create New Employee Profile
                    </Button>
                </div>

                {loading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <>
                        {allEmployee.length > 0 ? (
                            <table>
                                <thead>
                                    <tr className="tr__head">
                                        <th className="th"> Image </th>
                                        <th className="th"> Name </th>
                                        <th className="th"> Employee ID </th>
                                        <th className="th"> Designation </th>
                                        <th className="th"> Phone </th>
                                        <th className="th"> Category </th>
                                        <th className="th"> Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allEmployee.map((employee, index) => (
                                        <tr className="tr" key={index}>

                                            <td className="td">
                                                <Image width={65} src={employee.dp} alt="profile_picture" />
                                            </td>

                                            <td className="td"> {employee.name} </td>

                                            <td className="td"> {employee.employee_id} </td>

                                            <td className="td"> {employee.designation} </td>

                                            <td className="td"> {employee.phone_number} </td>

                                            <td className="td"> {employee.category} </td>

                                            <td className="td" style={{ cursor: "pointer" }}>
                                                <Tooltip title="Edit Employee" placement="left">
                                                    <FiEdit
                                                        className="sh__edit__products-icon"
                                                        onClick={() => {
                                                            setModalData(employee);
                                                            setShowModal(true);
                                                            setImages([employee.dp]);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <NoData />
                        )
                        }
                    </>
                )
                }

                {/* modal for edit category */}
                {modalData && (
                    <Modal
                        visible={showModal}
                        onCancel={() => {
                            setShowModal(false);
                            setModalData(null);
                        }}
                        afterClose={() => {
                            setModalData(null);
                        }}
                        closeIcon={<RiCloseCircleLine size={20} />}
                        width={600}
                        style={{ top: 20 }}
                        footer={null}
                    >
                        <h2 className="primary_h2"> Update Employee Profile </h2>
                        <Form
                            name="updateEmployeeProfile"
                            onFinish={handleUpdateEmployeeProfile}
                            layout="vertical"
                            initialValues={{
                                ...modalData,
                            }}
                        >
                            <div className="uploadLogo">
                                <label style={{ fontWeight: 600 }} htmlFor="logo">
                                    Profile Picture
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                        <div className="drag-text">
                                            {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                                        </div>
                                    </div>
                                    {images.length > 0 ? (
                                        <div style={{ marginTop: "2rem" }} className="h-center">
                                            {images.map((image, index) => {
                                                return <img key={index} width="200px" height="200px" src={image} alt="logo" />;
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <br />

                            <Form.Item label="Employee Name" name="name" rules={[{ required: true, message: "Please Add Employee Name" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Employee ID" name="employee_id" rules={[{ required: true, message: "Please Add Employee ID" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Fixed ID" name="fixed_id" rules={[{ required: true, message: "Please Add Fixed ID" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="category"
                                label="Employee Category"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Employee Category",
                                    },
                                ]}
                            >
                                <Select placeholder="Select a Category">
                                    <Option value="">
                                        Select a Category
                                    </Option>
                                    <Option value="tier-1">
                                        Tier 1
                                    </Option>
                                    <Option value="admin-support">
                                        Admin and Support
                                    </Option>
                                    <Option value="procurement">
                                        Procurement
                                    </Option>
                                    <Option value="operation">
                                        Operation
                                    </Option>
                                    <Option value="business_development">
                                        Business Development
                                    </Option>
                                    <Option value="accounting">
                                        Accounting
                                    </Option>
                                    <Option value="warehouse">
                                        Warehouse
                                    </Option>
                                    <Option value="marketing">
                                        Marketing
                                    </Option>
                                    <Option value="sales">
                                        Sales
                                    </Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="Designation" name="designation" rules={[{ required: true, message: "Please Add Designation" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please Add Email" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Phone Number" name="phone_number" rules={[{ required: true, message: "Please Add Phone Number" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateEmployeeLoading}>
                                    Update Employee Profile
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                )}

            </div>
        </Layout>
    )
}

export default Employees;