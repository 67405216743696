import { useHistory } from "react-router-dom";
import styles from "./AllStockOut.module.scss";
import { useState, useEffect } from 'react';
import moment from "moment";


// importing components
import WarehouseLayout from "../../../Components/WarehouseLayout/WarehouseLayout";
import { Button, DatePicker, Input, Pagination, Select, Spin, Tooltip } from "antd";
import axios from "../../../config/axios";
import NoData from "../../../Components/Library/NoData";
import SimpleLoader from "../../../Components/SimpleLoader/SimpleLoader";

// importing icons
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import StockOutTable from "../../../Components/StockOutTable/StockOutTable";
import StockOutEditModal from "../../../Components/StockOutEditModal/StockOutEditModal";

const { Option } = Select;


const AllStockOut = () => {
    let history = useHistory();
    const [allStockOutLoading, setAllStockOutLoading] = useState(true);
    const [allStockOut, setAllStockOut] = useState({});
    const [stockOutCount, setStockOutCount] = useState(0);
    const [skuSearchTerm, setSkuSearchTerm] = useState("");
    const [allWarehousesForSelect, setAllWarehousesForSelect] = useState([]);
    const [warehouseSearchTerm, setWarehouseSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(20);
    const [refresh, setRefresh] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
        console.log('stock out');

        const getAllWarehouseAndData = async () => {
            const res = await axios.get('/api/warehouses', { withCredentials: true })
            setAllWarehousesForSelect(res.data);
            const selectedWarehouse = window.localStorage.getItem('st_out_se_wa') ?
                parseInt(window.localStorage.getItem('st_out_se_wa')) :
                res.data[0].id;
            setWarehouseSearchTerm(selectedWarehouse)
            const response = await axios.get('/api/warehouses/stock-out', {
                withCredentials: true, params: {
                    page: 1,
                    limit: 20,
                    warehouse: selectedWarehouse
                }
            })
            setStockOutCount(response.data.count)
            setAllStockOut(groupBy(response.data.results));
            setAllStockOutLoading(false)
        }
        getAllWarehouseAndData()
        setFirstRender(true)
    }, []);


    useEffect(() => {
        const getAllStockOut = async () => {
            setSpin(true)
            const res = await axios.get('/api/warehouses/stock-out', {
                withCredentials: true, params: {
                    page: currentPage,
                    limit: limit,
                    sku: skuSearchTerm !== "" ? skuSearchTerm : null,
                    warehouse: warehouseSearchTerm !== "" ? warehouseSearchTerm : null,
                    start: startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : null,
                    end: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : null,
                }
            })
            setStockOutCount(res.data.count)
            console.log(groupBy(res.data.results));
            setAllStockOut(groupBy(res.data.results));
            setSpin(false)
            setAllStockOutLoading(false)
        }

        if (firstRender) {
            getAllStockOut();
        }

    }, [refresh, currentPage, limit]);

    const showTotal = (total) => {
        return `Total ${total} Stock Out`;
    };
    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };



    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // Accepts the array and key
    const groupBy = (array) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue.product["sku"]] = result[currentValue.product["sku"]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {});
    }


    return (
        <WarehouseLayout>
            {/* filter */}
            <div className={"filter " + styles.stock_out_filter}>
                <div>
                    <Input
                        placeholder="Search By SKU"
                        value={skuSearchTerm}
                        onChange={(e) => setSkuSearchTerm(e.target.value)} />
                </div>
                <div>
                    <Select
                        style={{ width: "100%" }}
                        value={warehouseSearchTerm}
                        onChange={(value) => {
                            setWarehouseSearchTerm(value);
                            window.localStorage.setItem('st_out_se_wa', value)
                        }}
                    >
                        <Option value="" hidden>
                            Search By Warehouse
                        </Option>
                        {allWarehousesForSelect?.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}

                    </Select>
                </div>
                <div>
                    <DatePicker
                        value={startDate}
                        allowClear={false}
                        placeholder="From Date"
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                    />
                </div>
                <div>
                    <DatePicker
                        value={endDate}
                        allowClear={false}
                        placeholder="To Date"
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                    />
                </div>

                <div style={{ marginLeft: "auto" }}>
                    <Button
                        disabled={skuSearchTerm || startDate || endDate ? false : true}
                        style={{
                            backgroundColor: "var(--color-dark-light)",
                            color: skuSearchTerm || startDate || endDate ? "red" : null,
                        }}
                        className="filter__item__button"
                        onClick={() => {
                            setSkuSearchTerm("");
                            setStartDate("");
                            setEndDate("");
                            setCurrentPage(1);
                            setRefresh(Math.random());
                        }}
                        icon={<CloseCircleOutlined />}
                    >
                        Clear Filter
                    </Button>
                </div>

                <div>
                    <Button
                        disabled={skuSearchTerm || warehouseSearchTerm || startDate || endDate ? false : true}
                        style={{ backgroundColor: "var(--color-dark)", color: "var(--color-light)" }}
                        onClick={() => {
                            setCurrentPage(1);
                            setRefresh(Math.random());
                        }}
                        className="filter__item__button"
                        icon={<SearchOutlined />}
                    >
                        Filter
                    </Button>
                </div>
            </div>
            <div className="card">
                <div className={styles.top_bar}>
                    <h2 className="primary_h2"> All Stock Out</h2>
                    <Button type="primary" onClick={() => history.push("/warehouses/create-stock-out")}>
                        Create New Stock Out
                    </Button>
                </div>

                {allStockOutLoading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <>
                        {allStockOut && (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                <div className={styles.stock_out_table}>
                                    <table>
                                        <thead>
                                            <tr className="tr__head">
                                                <th className="th"> TimeStamp </th>
                                                <th className="th"> Product </th>
                                                <th className="th"> Sku </th>
                                                <th className="th"> Warehouse </th>
                                                <th className="th"> Rack </th>
                                                <th className="th"> SubRack </th>
                                                <th className="th"> Quantity </th>
                                                <th className="th"> Remarks </th>
                                                <th className="th"> Action </th>
                                            </tr>
                                        </thead>

                                        {
                                            Object.keys(allStockOut).map((stock, index) => {
                                                return <StockOutTable
                                                    key={index}
                                                    index={index}
                                                    stock={stock}
                                                    allStockOut={allStockOut}
                                                    setModalData={setModalData}
                                                />
                                            })
                                        }

                                    </table>
                                </div>
                                <div className="sh__pagination__center">
                                    <Pagination
                                        showTotal={showTotal}
                                        total={stockOutCount}
                                        onChange={handleCurrentPage}
                                        defaultPageSize={limit}
                                        current={currentPage}
                                        onShowSizeChange={onShowSizeChange}
                                    />
                                </div>
                            </Spin>
                        )}
                    </>
                )}

                {/* show edit stock modal when true */}
                {
                    modalData &&
                    <StockOutEditModal
                        modalData={modalData}
                        setModalData={setModalData}
                    />
                }


            </div>
        </WarehouseLayout>
    );
}

export default AllStockOut;