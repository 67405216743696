import Layout from "../Components/Layout";
import axios from "../config/axios";
import { useState, useEffect } from 'react';
import { Button, Input, Pagination, Spin } from "antd";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";



const PushNotificationTokens = () => {
    const [tokens, setTokens] = useState([]);
    const [tokenCount, setTokenCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tokensLoading, setTokensLoading] = useState(true);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(20);
    const [searchTerm, setSearchTerm] = useState(null);
    const [refresh, setRefresh] = useState(null);

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Tokens`;
    };
    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    const getAllTokens = async () => {
        setSpin(true);
        const res = await axios.get('/api/admin/communications/push-notification-token', {
            withCredentials: true, params: {
                page: currentPage,
                limit: limit,
                phone_number: searchTerm ?? null,
            }
        })
        console.log(res.data);
        setTokenCount(res.data.count)
        setTokens(res.data.results)
        setSpin(false);
        setTokensLoading(false);
    }

    useEffect(() => {
        getAllTokens()
    }, [currentPage, limit, refresh]);

    return (
        <Layout pageName="Push Notification Tokens">
            <div className="filter">
                <div>
                    <Input
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search By Phone Number"
                        style={{ width: "100%" }}
                    />
                </div>

                <div className="merchant_ml_auto">
                    <Button
                        onClick={() => {
                            setSearchTerm(null);
                            setCurrentPage(1);
                            setRefresh(Math.random());
                        }}
                        style={{ backgroundColor: "var(--color-dark-light)", color: searchTerm ? "red" : null }}
                        className="filter__item__button"
                        disabled={searchTerm ? false : true}
                        icon={<CloseCircleOutlined />}
                    >
                        Clear Filter
                    </Button>
                </div>

                <div style={{ marginRight: "0" }}>
                    <Button
                        onClick={() => {
                            setCurrentPage(1);
                            getAllTokens();
                        }}
                        disabled={searchTerm ? false : true}
                        style={{ backgroundColor: "var(--color-dark)", color: "var(--color-light)" }}
                        className="filter__item__button"
                        icon={<SearchOutlined />}
                    >
                        Filter
                    </Button>
                </div>
            </div>

            <div className="sh__Customers">
                <h2 className="primary_h2"> Push Notification Tokens</h2>
                {tokensLoading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                        <div className="sh__CustomersTable">
                            <table>
                                <thead>
                                    <tr className="tr__head">
                                        <th className="th">Token Id</th>
                                        <th className="th">Token</th>
                                        <th className="th">Platform</th>
                                        <th className="th">User</th>
                                        <th className="th">User Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tokens?.map((token, index) => (
                                        <tr className="tr" key={index}>
                                            <td className="td"> {token.id} </td>
                                            <td className="td"> {token.token} </td>
                                            <td className="td" style={{ textTransform: 'uppercase' }}> {token.platform ? token.platform : 'android'} </td>
                                            <td className="td"> {token.user?.name || "N/A"} </td>
                                            <td className="td"> {token.user?.phone_number || "N/A"} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="sh__pagination__center">
                            <Pagination
                                showTotal={showTotal}
                                size="small"
                                total={tokenCount}
                                onChange={handleCurrentPage}
                                pageSize={limit}
                                current={currentPage}
                                onShowSizeChange={onShowSizeChange}
                            />
                        </div>
                    </Spin>
                )}
            </div>
        </Layout>
    );
}

export default PushNotificationTokens;