import Layout from "../Components/Layout";
import { Row, Col, Form, Input, Button, Tooltip, Select, Modal, message, Spin, Pagination, Popconfirm, Switch } from "antd";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { IoRefreshOutline } from "react-icons/io5";
import axios from "../config/axios";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import { RiCloseCircleLine } from "react-icons/ri";
import { WarningOutlined } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
const { Option } = Select;
const { TextArea } = Input;

const SubSubcategories = () => {
    const [allCategories, setAllCategories] = useState([]);
    const [allSubSubCategories, setAllSubSubCategories] = useState([]);
    const [subSubCategoryLoading, setSubSubCategoryLoading] = useState(true);
    const [refresh, setRefresh] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [spin, setSpin] = useState(false);
    const [subSubCategoryCount, setSubSubCategoryCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [refreshSpec, setRefreshSpec] = useState(false);
    const [specifications, setSpecifications] = useState([]);

    // get All Categories for form and render on useEffect
    const getAllCategories = async () => {
        const res = await axios.get("/api/public/categories", {
            withCredentials: true,
        });
        setAllCategories(res.data);
    };
    useEffect(() => {
        getAllCategories();
    }, []);

    // get All Sub Categories From API and render on useEffect
    const getAllSubSubCategories = async () => {
        setSpin(true);
        const res = await axios.get("/api/public/sub_subcategories", {
            withCredentials: true,
            params: {
                limit: limit,
                page: searchTerm ? 1 : currentPage,
                search_term: searchTerm !== "" ? searchTerm : null,
            },
        });
        setSubSubCategoryCount(res.data.count);
        setAllSubSubCategories(res.data.results);
        setSpin(false);
        setSubSubCategoryLoading(false);
    };
    useEffect(() => {
        getAllSubSubCategories();
        // eslint-disable-next-line
    }, [refresh, currentPage, limit]);

    // get debounced search results from search term
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllSubSubCategories();
        }, 300);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [searchTerm]);

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    const getAllSpecifications = async () => {
        setRefreshSpec(true);
        const res = await axios.get("/api/admin/specifications?limit=500", {
            withCredentials: true,
        });
        setSpecifications(res.data.results);
        setRefreshSpec(false);
    };
    useEffect(() => {
        getAllSpecifications();
    }, []);

    return (
        <Layout pageName="SubSubcategories">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <SubSubCategoryAddForm
                        categories={allCategories}
                        setRefresh={setRefresh}
                        refreshSpec={refreshSpec}
                        getAllSpecifications={getAllSpecifications}
                        specifications={specifications}
                    />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllSubSubCategories
                        handleCurrentPage={handleCurrentPage}
                        limit={limit}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        subSubCategoryCount={subSubCategoryCount}
                        subSubcategories={allSubSubCategories}
                        loading={subSubCategoryLoading}
                        categories={allCategories}
                        setRefresh={setRefresh}
                        spin={spin}
                        setSearchTerm={setSearchTerm}
                        specifications={specifications}
                    />
                </Col>
            </Row>
        </Layout>
    );
};

export default SubSubcategories;

// sub components
const SubSubCategoryAddForm = ({ categories, setRefresh, refreshSpec, getAllSpecifications, specifications }) => {
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);
    const [loadingGetSubcategory, setLoadingGetSubcategory] = useState(false);
    const [addSubSubCategoryLoading, setAddSubSubCategoryLoading] = useState(false);

    const [form] = Form.useForm();

    // get sub categories based on selected category
    const getSubCategoryByCategory = async (values) => {
        setLoadingGetSubcategory(true);
        const res = await axios.get(`/api/public/subcategories?category_slug=${values}`);
        if (res.data.length === 0) {
            message.error("No subcategory found under this category. Choice another one that already have subcategories");
        }
        setSubCategoriesByCategory(res.data);
        setLoadingGetSubcategory(false);
    };

    // handle form with  all data
    const handleAddSubSubCategory = async (values) => {
        setAddSubSubCategoryLoading(true);
        await axios.post("/api/sub_subcategories", values, {
            withCredentials: true,
        });
        message.success("added new sub SubCategory");
        setAddSubSubCategoryLoading(false);
        form.resetFields();
        setAddSubSubCategoryLoading(false);
        setRefresh(Math.random());
    };

    return (
        <div className="card-primary">
            <h2 className="primary_h2">Add New Sub SubCategory</h2>
            <Form name="addNewSubSubCategory" form={form} onFinish={handleAddSubSubCategory} layout="vertical">
                <Form.Item label="Sub Sub Category Name" name="name" rules={[{ required: true, message: "Please Add Category Name" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="category_slug"
                    label="Product Category"
                    rules={[
                        {
                            required: true,
                            message: "Select Product Category",
                        },
                    ]}
                >
                    <Select placeholder="Select a Category" onChange={getSubCategoryByCategory}>
                        {categories.map((option, index) => (
                            <Option value={option.slug} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="sub_category_slug"
                    label="Product Sub Category"
                    rules={[
                        {
                            required: true,
                            message: "Select Product Sub Category",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a Sub Category"
                        loading={loadingGetSubcategory}
                        allowClear
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {subCategoriesByCategory.map((option, index) => (
                            <Option value={option.slug} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Specifications */}

                <Form.Item
                    name="specifications"
                    label="Select Specifications"
                    tooltip={{
                        title: "Refresh",
                        icon: (
                            <IoRefreshOutline
                                id={refreshSpec && "specifications_refresh_btn_loading"}
                                onClick={getAllSpecifications}
                                size={20}
                                style={{ cursor: "pointer" }}
                            />
                        ),
                    }}
                >
                    <Select
                        placeholder="Select Specifications"
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {specifications.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Sub Sub Category Description" name="description">
                    <TextArea rows={2} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addSubSubCategoryLoading}>
                        Add New Sub SubCategory
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const ShowAllSubSubCategories = ({
    subSubcategories,
    loading,
    categories,
    setRefresh,
    handleCurrentPage,
    limit,
    setLimit,
    currentPage,
    subSubCategoryCount,
    spin,
    setSearchTerm,
    specifications,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);
    const [loadingGetSubcategory, setLoadingGetSubcategory] = useState(false);

    const [updateCategoryLoading, setUpdateCategoryLoading] = useState(false);

    // get sub categories based on selected category
    const getSubCategoryByCategory = async (values) => {
        setLoadingGetSubcategory(true);
        const res = await axios.get(`/api/public/subcategories?category_slug=${values}`);
        if (res.data.length === 0) {
            message.error("No subcategory found under this category. Choice another one that already have subcategories");
        }
        setSubCategoriesByCategory(res?.data);
        setLoadingGetSubcategory(false);
        setLoadingGetSubcategory(false);
    };

    // updating sub subCategory Handler
    const handleUpdateSubSubCategory = async (values) => {
        setUpdateCategoryLoading(true);
        await axios.put(`/api/sub_subcategories/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the subSubCategory");
        setUpdateCategoryLoading(false);
        setShowModal(false);
        // setRefresh(Math.random());
        window.location.reload();
    };

    // handle pagination's total data show string
    const showTotal = (total) => {
        return `Total ${total} Sub SubCategories`;
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // sub sub category delete function
    const deleteSubSubCategory = async (id) => {
        try {
            await axios.delete(`/api/admin/sub_subcategories/${id}`, { withCredentials: true });
            message.success("Deleted the Sub Sub Category");
            setRefresh(Math.random());
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        }
    }

    return (
        <div className="card-primary">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <h2 className="primary_h2">All Sub SubCategories</h2>
                <Input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    placeholder="Search Sub SubCategories"
                    suffix={<AiOutlineSearch size={20} />}
                    bordered={false}
                    style={{ width: 230 }}
                />
            </div>
            {loading ? (
                <SimpleLoader height="80vh" />
            ) : (
                <Spin spinning={spin} indicator={<SimpleLoader height={"60vh"} />}>
                    <div className="sub_subcategories_table">
                        <table>
                            <thead>
                                <tr className="tr__head">
                                    <th className="th"> Name </th>
                                    <th className="th"> Category </th>
                                    <th className="th"> Sub Category </th>
                                    <th className="th">Is Visible</th>
                                    <th className="th"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {subSubcategories?.map((subSubcategory, index) => (
                                    <SubSubcategoryItem
                                        subSubcategory={subSubcategory}
                                        key={index}
                                        setModalData={setModalData}
                                        getSubCategoryByCategory={getSubCategoryByCategory}
                                        setShowModal={setShowModal}
                                        deleteSubSubCategory={deleteSubSubCategory}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination__wrapper">
                        <Pagination
                            showTotal={showTotal}
                            onShowSizeChange={onShowSizeChange}
                            size="small"
                            total={subSubCategoryCount}
                            onChange={handleCurrentPage}
                            pageSize={limit}
                            current={currentPage}
                        />
                    </div>
                </Spin>
            )}

            {/* modal for edit category */}
            {modalData && (
                <Modal
                    visible={showModal}
                    onCancel={() => {
                        setShowModal(false);
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20} />}
                    width={600}
                    style={{ top: 20 }}
                    footer={null}
                >
                    <h2 className="primary_h2"> Update Your Sub SubCategory Data </h2>
                    <Form
                        name="addNewSubSubCategory"
                        layout="vertical"
                        onFinish={handleUpdateSubSubCategory}
                        initialValues={{
                            ...modalData,
                        }}
                    >
                        <Form.Item label="Sub Sub Category Name" name="name" rules={[{ required: true, message: "Please Add Category Name" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Bangla Name" name="bn_name" rules={[{ required: true, message: "Please Add Bangla Name" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="category_slug"
                            label="Product Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Product Category",
                                },
                            ]}
                        >
                            <Select placeholder="Select a Category" onChange={getSubCategoryByCategory}>
                                {categories.map((option, index) => (
                                    <Option value={option.slug} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="sub_category_slug"
                            label="Product Sub Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Select Product Sub Category",
                                },
                            ]}
                        >
                            <Select placeholder="Select a Sub Category" loading={loadingGetSubcategory}>
                                {subCategoriesByCategory.map((option, index) => (
                                    <Option value={option.slug} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Specifications */}
                        <Form.Item
                            name="specifications"
                            label="Select Specifications"
                            rules={[{ required: true, message: "Specifications is a required field" }]}
                        >
                            <Select
                                placeholder="Select Specifications"
                                allowClear
                                mode="multiple"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {specifications.map((option, index) => (
                                    <Option value={option.id} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Sub Sub Category Description" name="description">
                            <TextArea rows={2} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateCategoryLoading}>
                                Update Sub SubCategory
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};


const SubSubcategoryItem = ({ subSubcategory, setModalData, getSubCategoryByCategory, setShowModal, deleteSubSubCategory }) => {
    const [isVisible, setIsVisible] = useState(() => subSubcategory.is_visible);

    useEffect(() => {
        setIsVisible(subSubcategory.is_visible)
    }, [subSubcategory]);


    //handle updateSubSubCategoryVisibility function
    const updateSubSubCategoryVisibility = async (value) => {
        setIsVisible(value);
        let payload = {
            is_visible: value
        }
        // hit endpoint with all values
        await axios.put(`/api/sub_subcategories/${subSubcategory.id}`, payload, {
            withCredentials: true,
        });
        message.success("Sub SubCategory visibility updated successfully!");
    }

    return (
        <tr className="tr">
            <td className="td"> {subSubcategory.name} </td>
            <td className="td"> {subSubcategory.category_slug} </td>
            <td className="td"> {subSubcategory.sub_category_slug} </td>
            <td className="td">
                <Switch checked={isVisible} onChange={value => updateSubSubCategoryVisibility(value)} />
            </td>
            <td className="td" style={{ cursor: "pointer", textAlign: "center", minWidth: "7.5rem" }}>
                <Tooltip title="Edit subSubcategory" placement="left">
                    <FiEdit
                        className="sh__edit__products-icon"
                        onClick={() => {
                            setModalData(subSubcategory);
                            getSubCategoryByCategory(subSubcategory.category_slug);
                            setShowModal(true);
                        }}
                        style={{ marginRight: "0.5rem" }}
                    />
                </Tooltip>

                <Popconfirm
                    title="Are You Sure? This Action Can't be Undone."
                    placement="topRight"
                    onConfirm={() => {
                        deleteSubSubCategory(subSubcategory.id)
                    }}
                    okText="Confirm"
                    icon={<WarningOutlined style={{ color: 'red' }} />}
                >
                    <MdDelete title="Delete Sub SubCategory"
                        className="sh__edit__products-icon"
                    />
                </Popconfirm>
            </td>
        </tr>
    )
}