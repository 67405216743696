import { Link, useLocation } from "react-router-dom";

//importing components
import { Button } from "antd";

const ShopMenu = () => {
  const location = useLocation();

  return (
    <div className="card f-sb">
      <Link to="/shop">
        {/* TODO:Please repeat this for all buttons  */}
        <Button type={location.pathname === "/shop" ? "primary" : null}>
          View All Products
        </Button>
      </Link>
      <Link to="/create-products">
        <Button
          type={location.pathname === "/create-products" ? "primary" : null}
        >
          Create Product
        </Button>
      </Link>
      <Link to="/view-all-catalogue">
        <Button
          type={location.pathname === "/view-all-catalogue" ? "primary" : null}
        >
          View All Catalogues
        </Button>
      </Link>
      <Link to="/create-catalogue">
        <Button
          type={location.pathname === "/create-catalogue" ? "primary" : null}
        >
          Create Catalogue
        </Button>
      </Link>
      <Link to="/shop">
        <Button>Some Action</Button>
      </Link>
    </div>
  );
};

export default ShopMenu;
