import { DeleteOutlined, EditOutlined, MenuOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, DatePicker, Divider, Form, Image, Input, List, Modal, Table, Tabs, message } from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Layout from "../../Components/Layout";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import { uploadImage } from "../../api/fundamentals";
import axios from "../../config/axios";
import styles from "./Settings.module.scss";
import fetch_error from "./assets/fetch_error.svg";

const Settings = () => {
    return (
        <Layout pageName="Settings">
            <div className={styles.main}>
                <Tabs
                    defaultActiveKey="1"
                    animated
                    items={[
                        {
                            key: "1",
                            label: <div className={styles.tab_title}>General</div>,
                            children: <GeneralSettings />,
                        },
                        {
                            key: "2",
                            label: <div className={styles.tab_title}>Flash Sale</div>,
                            children: <FlashSale />,
                        },
                        {
                            key: "3",
                            label: <div className={styles.tab_title}>News</div>,
                            children: <MarqueeNews />,
                        },
                    ]}
                />
            </div>
        </Layout>
    );
};

export default Settings;

const GeneralSettings = () => {
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [dataSourcePS, setDataSourcePS] = useState([]);
    const [addNewPSModalOpen, setAddNewPSModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [toEditPS, setToEditPS] = useState({});
    const [editPSModal, setEditPSModal] = useState(false);
    const [dataSourceSS, setDataSourceSS] = useState([]);
    const [addNewSSModalOpen, setAddNewSSModalOpen] = useState(false);
    const [toEditSS, setToEditSS] = useState({});
    const [editSSModal, setEditSSModal] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const settings = (await axios.get("/api/public/settings", { withCredentials: true })).data.results;
                setSettings(settings);

                // set table data source
                const primary_slider = JSON.parse(settings.primary_slider);
                const secondary_slider = JSON.parse(settings.secondary_slider);
                const dataSourcePS = primary_slider.map((item, index) => ({
                    key: index + 1,
                    ...item,
                }));

                const dataSourceSS = secondary_slider.map((item, index) => ({
                    key: index + 1,
                    ...item,
                }));

                setDataSourcePS(dataSourcePS);
                setDataSourceSS(dataSourceSS);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [refresh]);

    const onDragEndPS = async ({ active, over }) => {
        if (active.id !== over?.id) {
            // reorder primary slider
            const activeIndexPS = dataSourcePS.findIndex((i) => i.key === active.id);
            const overIndexPS = dataSourcePS.findIndex((i) => i.key === over?.id);
            const reorderedDataSourcePS = arrayMove(dataSourcePS, activeIndexPS, overIndexPS);

            setDataSourcePS(reorderedDataSourcePS);

            // update primary slider in database
            const payload = {
                primary_slider: JSON.stringify(reorderedDataSourcePS),
            };

            await axios.patch("/api/admin/settings", payload, { withCredentials: true });

            message.success("Primary slider updated successfully");
        }
    };

    const onDragEndSS = async ({ active, over }) => {
        if (active.id !== over?.id) {
            // reorder secondary slider
            const activeIndexSS = dataSourceSS.findIndex((i) => i.key === active.id);
            const overIndexSS = dataSourceSS.findIndex((i) => i.key === over?.id);
            const reorderedDataSourceSS = arrayMove(dataSourceSS, activeIndexSS, overIndexSS);

            setDataSourceSS(reorderedDataSourceSS);

            // update primary slider in database
            const payload = {
                secondary_slider: JSON.stringify(reorderedDataSourceSS),
            };

            await axios.patch("/api/admin/settings", payload, { withCredentials: true });

            message.success("Secondary slider updated successfully");
        }
    };

    return (
        <Fragment>
            {loading && <SimpleLoader height={"80vh"} />}

            {!loading && error && (
                <div className={styles.fetch_error}>
                    <img src={fetch_error} alt="fetch_error" />
                    <p className={styles.fetch_error_title}>Uh oh! Something went wrong.</p>
                </div>
            )}

            {!loading && !error && (
                <>
                    <div>
                        <h2 className="mb-3 ms-2">Primary Slider</h2>

                        {dataSourcePS.length === 0 && (
                            <div
                                className="p-5 border d-flex justify-content-center flex-column text-center"
                                style={{
                                    borderRadius: 8,
                                }}
                            >
                                <p>No Slider To Show!</p>
                                <div className={styles.add_new_slider}>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddNewPSModalOpen(true)}>
                                        Add New Slider
                                    </Button>
                                </div>
                            </div>
                        )}

                        {dataSourcePS.length > 0 && (
                            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndPS}>
                                <SortableContext items={dataSourcePS.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                                    <Table
                                        components={{
                                            body: {
                                                row: SortableRow,
                                            },
                                        }}
                                        rowKey="key"
                                        columns={[
                                            {
                                                key: "sort",
                                                title: "Reorder",
                                                dataIndex: "sort",
                                                align: "center",
                                            },
                                            {
                                                key: "image",
                                                title: "Image",
                                                dataIndex: "image",
                                                render: (image) => <Image src={image} height={70} />,
                                                centered: true,
                                            },
                                            {
                                                key: "link",
                                                title: "Link",
                                                dataIndex: "link",
                                                render: (link) => (
                                                    <a href={link} target="_blank" rel="noreferrer">
                                                        {link}
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: "actions",
                                                title: "Actions",
                                                render: (record) => (
                                                    <div className={styles.action_btn}>
                                                        <Button
                                                            type="dashed"
                                                            icon={<EditOutlined />}
                                                            onClick={() => {
                                                                setToEditPS(record);
                                                                setEditPSModal(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>

                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => {
                                                                Modal.confirm({
                                                                    title: "Are you sure you want to delete this item?",
                                                                    icon: <WarningOutlined />,
                                                                    content: "This action cannot be undone.",
                                                                    centered: true,
                                                                    async onOk() {
                                                                        // find the index of the data to be deleted
                                                                        const index = dataSourcePS.findIndex((i) => i.key === record.key);

                                                                        // remove the data from dataSource
                                                                        dataSourcePS.splice(index, 1);

                                                                        // update primary slider in database
                                                                        const payload = {
                                                                            primary_slider: JSON.stringify(dataSourcePS),
                                                                        };

                                                                        try {
                                                                            await axios.patch("/api/admin/settings", payload, {
                                                                                withCredentials: true,
                                                                            });
                                                                            message.success("Primary slider updated successfully");
                                                                            setRefresh((prev) => prev + 1);
                                                                        } catch (error) {
                                                                            message.error("A server error occurred");
                                                                        }
                                                                    },
                                                                    onCancel() {
                                                                        message.info("No action taken");
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        dataSource={dataSourcePS}
                                        pagination={false}
                                        bordered
                                        size="small"
                                        footer={() => (
                                            <div className={styles.add_new_slider}>
                                                <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddNewPSModalOpen(true)}>
                                                    Add New Slider
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </SortableContext>
                            </DndContext>
                        )}
                    </div>

                    <div className="mt-4">
                        <h2 className="mb-3 ms-2">Secondary Slider</h2>
                        {dataSourceSS.length === 0 && (
                            <div
                                className="p-5 border d-flex justify-content-center flex-column text-center"
                                style={{
                                    borderRadius: 8,
                                }}
                            >
                                <p>No Slider To Show!</p>
                                <div className={styles.add_new_slider}>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddNewSSModalOpen(true)}>
                                        Add New Slider
                                    </Button>
                                </div>
                            </div>
                        )}
                        {dataSourceSS.length > 0 && (
                            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndSS}>
                                <SortableContext items={dataSourceSS.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                                    <Table
                                        components={{
                                            body: {
                                                row: SortableRow,
                                            },
                                        }}
                                        rowKey="key"
                                        columns={[
                                            {
                                                key: "sort",
                                                title: "Reorder",
                                                dataIndex: "sort",
                                                align: "center",
                                            },
                                            {
                                                key: "image",
                                                title: "Image",
                                                dataIndex: "image",
                                                render: (image) => <Image src={image} height={70} />,
                                                centered: true,
                                            },
                                            {
                                                key: "link",
                                                title: "Link",
                                                dataIndex: "link",
                                                render: (link) => (
                                                    <a href={link} target="_blank" rel="noreferrer">
                                                        {link}
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: "actions",
                                                title: "Actions",
                                                render: (record) => (
                                                    <div className={styles.action_btn}>
                                                        <Button
                                                            type="dashed"
                                                            icon={<EditOutlined />}
                                                            onClick={() => {
                                                                setToEditSS(record);
                                                                setEditSSModal(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>

                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => {
                                                                Modal.confirm({
                                                                    title: "Are you sure you want to delete this item?",
                                                                    icon: <WarningOutlined />,
                                                                    content: "This action cannot be undone.",
                                                                    centered: true,
                                                                    async onOk() {
                                                                        // find the index of the data to be deleted
                                                                        const index = dataSourceSS.findIndex((i) => i.key === record.key);

                                                                        // remove the data from dataSource
                                                                        dataSourceSS.splice(index, 1);

                                                                        // update secondary slider in database
                                                                        const payload = {
                                                                            secondary_slider: JSON.stringify(dataSourceSS),
                                                                        };

                                                                        try {
                                                                            await axios.patch("/api/admin/settings", payload, {
                                                                                withCredentials: true,
                                                                            });
                                                                            message.success("Secondary slider updated successfully");
                                                                            setRefresh((prev) => prev + 1);
                                                                        } catch (error) {
                                                                            message.error("A server error occurred");
                                                                        }
                                                                    },
                                                                    onCancel() {
                                                                        message.info("No action taken");
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        dataSource={dataSourceSS}
                                        pagination={false}
                                        bordered
                                        size="small"
                                        footer={() => (
                                            <div className={styles.add_new_slider}>
                                                <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddNewSSModalOpen(true)}>
                                                    Add New Slider
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </SortableContext>
                            </DndContext>
                        )}
                    </div>
                </>
            )}

            <AddNewPrimarySliderModal
                open={addNewPSModalOpen}
                close={() => setAddNewPSModalOpen(false)}
                settings={settings}
                setRefresh={setRefresh}
            />

            <AddNewSecondarySliderModal
                open={addNewSSModalOpen}
                close={() => setAddNewSSModalOpen(false)}
                settings={settings}
                setRefresh={setRefresh}
            />

            <UpdatePrimarySliderModal
                open={editPSModal}
                close={() => setEditPSModal(false)}
                setRefresh={setRefresh}
                dataSource={dataSourcePS}
                toEditPS={toEditPS}
            />

            <UpdateSecondarySliderModal
                open={editSSModal}
                close={() => setEditSSModal(false)}
                setRefresh={setRefresh}
                dataSource={dataSourceSS}
                toEditSS={toEditSS}
            />
        </Fragment>
    );
};

const SortableRow = ({ children, ...props }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id: props["data-row-key"],
    });

    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            }
        ),
        transition,
        ...(isDragging
            ? {
                  position: "relative",
                  zIndex: 9999,
              }
            : {}),
    };
    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === "sort") {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: "none",
                                    cursor: "move",
                                    fontSize: "1.8rem",
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

const AddNewPrimarySliderModal = ({ open, close, settings, setRefresh }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleAddPrimarySlider = async () => {
        if (images.length === 0) return message.error("Please select an image");
        if (link === "") return message.error("Please enter a link");

        // format data
        const data = {
            image: images[0],
            link,
        };

        // check if primary_slider has data, and concat the new data
        let primary_slider = JSON.parse(settings.primary_slider);
        if (primary_slider.length > 0) {
            primary_slider = [...primary_slider, data];
        } else {
            primary_slider = [data];
        }

        // update primary slider in database
        const payload = {
            primary_slider: JSON.stringify(primary_slider),
        };

        try {
            setLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Primary slider updated successfully");
            close();
            setRefresh((prev) => prev + 1);
            setImages([]);
            setLink("");
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Add Primary Slider" open={open} onCancel={close} centered footer={null}>
            <div className="uploadFeaturedImage">
                <label style={{ fontWeight: 600 }} htmlFor="logo">
                    Image
                </label>
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                        <div className="drag-text">
                            {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                        </div>
                    </div>
                    {images.length > 0 ? (
                        <div style={{ marginTop: "2rem" }} className="h-center">
                            {images.map((image, index) => {
                                return <img key={index} width="50%" src={image} alt="logo" />;
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="mt-3">
                <label htmlFor="link">Link</label>
                <Input placeholder="https://www.cbih.com.bd/products?category=5" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>

            <div className="mt-4">
                <Button type="primary" block onClick={handleAddPrimarySlider} loading={loading} disabled={loading}>
                    Add
                </Button>
            </div>
        </Modal>
    );
};

const AddNewSecondarySliderModal = ({ open, close, settings, setRefresh }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleAddSecondarySlider = async () => {
        if (images.length === 0) return message.error("Please select an image");
        if (link === "") return message.error("Please enter a link");

        // format data
        const data = {
            image: images[0],
            link,
        };

        // check if primary_slider has data, and concat the new data
        let secondary_slider = JSON.parse(settings.secondary_slider);
        if (secondary_slider.length > 0) {
            secondary_slider = [...secondary_slider, data];
        } else {
            secondary_slider = [data];
        }

        // update primary slider in database
        const payload = {
            secondary_slider: JSON.stringify(secondary_slider),
        };

        try {
            setLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Secondary slider updated successfully");
            close();
            setRefresh((prev) => prev + 1);
            setImages([]);
            setLink("");
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Add Secondary Slider" open={open} onCancel={close} centered footer={null}>
            <div className="uploadFeaturedImage">
                <label style={{ fontWeight: 600 }} htmlFor="logo">
                    Image
                </label>
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                        <div className="drag-text">
                            {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                        </div>
                    </div>
                    {images.length > 0 ? (
                        <div style={{ marginTop: "2rem" }} className="h-center">
                            {images.map((image, index) => {
                                return <img key={index} width="50%" src={image} alt="logo" />;
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="mt-3">
                <label htmlFor="link">Link</label>
                <Input placeholder="https://www.cbih.com.bd/products?category=5" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>

            <div className="mt-4">
                <Button type="primary" block onClick={handleAddSecondarySlider} loading={loading} disabled={loading}>
                    Add
                </Button>
            </div>
        </Modal>
    );
};

const UpdatePrimarySliderModal = ({ open, close, dataSource, setRefresh, toEditPS }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    // set initial values
    useEffect(() => {
        setImages([toEditPS.image]);
        setLink(toEditPS.link);
    }, [toEditPS]);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleUpdatePrimarySlider = async () => {
        if (images.length === 0) return message.error("Please select an image");
        if (link === "") return message.error("Please enter a link");

        // format data
        const data = {
            image: images[0],
            link,
            key: toEditPS.key,
        };

        // find the index of the data to be updated
        const index = dataSource.findIndex((i) => i.key === toEditPS.key);

        // update dataSource
        dataSource[index] = data;

        // update primary slider in database
        const payload = {
            primary_slider: JSON.stringify(dataSource),
        };

        try {
            setLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Primary slider updated successfully");
            close();
            setRefresh((prev) => prev + 1);
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Update Slider" open={open} onCancel={close} centered footer={null}>
            <div className="uploadFeaturedImage">
                <label style={{ fontWeight: 600 }} htmlFor="logo">
                    Image
                </label>
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                        <div className="drag-text">
                            {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                        </div>
                    </div>
                    {images.length > 0 ? (
                        <div style={{ marginTop: "2rem" }} className="h-center">
                            {images.map((image, index) => {
                                return <img key={index} width="50%" src={image} alt="logo" />;
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="mt-3">
                <label htmlFor="link">Link</label>
                <Input placeholder="https://www.cbih.com.bd/products?category=5" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>

            <div className="mt-4">
                <Button type="primary" block onClick={handleUpdatePrimarySlider} loading={loading} disabled={loading}>
                    Update
                </Button>
            </div>
        </Modal>
    );
};

const UpdateSecondarySliderModal = ({ open, close, dataSource, setRefresh, toEditSS }) => {
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    // set initial values
    useEffect(() => {
        setImages([toEditSS.image]);
        setLink(toEditSS.link);
    }, [toEditSS]);

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        // console.log(e.target.files[0]);
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const handleUpdatePrimarySlider = async () => {
        if (images.length === 0) return message.error("Please select an image");
        if (link === "") return message.error("Please enter a link");

        // format data
        const data = {
            image: images[0],
            link,
            key: toEditSS.key,
        };

        // find the index of the data to be updated
        const index = dataSource.findIndex((i) => i.key === toEditSS.key);

        // update dataSource
        dataSource[index] = data;

        // update secondary slider in database
        const payload = {
            secondary_slider: JSON.stringify(dataSource),
        };

        try {
            setLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Secondary slider updated successfully");
            close();
            setRefresh((prev) => prev + 1);
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Update Slider" open={open} onCancel={close} centered footer={null}>
            <div className="uploadFeaturedImage">
                <label style={{ fontWeight: 600 }} htmlFor="logo">
                    Image
                </label>
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                        <div className="drag-text">
                            {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                        </div>
                    </div>
                    {images.length > 0 ? (
                        <div style={{ marginTop: "2rem" }} className="h-center">
                            {images.map((image, index) => {
                                return <img key={index} width="50%" src={image} alt="logo" />;
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="mt-3">
                <label htmlFor="link">Link</label>
                <Input placeholder="https://www.cbih.com.bd/products?category=5" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>

            <div className="mt-4">
                <Button type="primary" block onClick={handleUpdatePrimarySlider} loading={loading} disabled={loading}>
                    Update
                </Button>
            </div>
        </Modal>
    );
};

const FlashSale = () => {
    const [settings, setSettings] = useState({
        flash_sale_start: "",
        flash_sale_end: "",
    });
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(true);
    const [flashSaleUpdateLoading, setFlashSaleUpdateLoading] = useState(false);
    const [closeFSLoading, setCloseFSLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const settings = (await axios.get("/api/public/settings", { withCredentials: true })).data.results;
            setSettings({
                flash_sale_start: settings.flash_sale_start,
                flash_sale_end: settings.flash_sale_end,
            });
            setLoading(false);
        })();
    }, [refresh]);

    const handleFlashSaleRangeUpdate = async (fieldsValue) => {
        const rangeTimeValue = fieldsValue["range-time-picker"];
        const payload = {
            flash_sale_start: rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
            flash_sale_end: rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
        };
        try {
            setFlashSaleUpdateLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Flash sale updated successfully");
            setRefresh((prev) => prev + 1);
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setFlashSaleUpdateLoading(false);
        }
    };

    const handleCloseFlashSale = async () => {
        const payload = {
            flash_sale_start: null,
            flash_sale_end: null,
        };
        try {
            setCloseFSLoading(true);
            await axios.patch("/api/admin/settings", payload, { withCredentials: true });
            message.success("Flash sale closed successfully");
            setRefresh((prev) => prev + 1);
        } catch (error) {
            message.error("A server error occurred");
        } finally {
            setCloseFSLoading(false);
        }
    };

    return (
        <>
            {loading && <SimpleLoader height={"80vh"} />}

            {!loading && (
                <>
                    <Divider>Current Flash Sale</Divider>
                    {settings.flash_sale_start && settings.flash_sale_end ? (
                        <div>
                            <div className="fs-4 d-flex gap-2">
                                Current Flash Sale Expires In - {dayjs(settings.flash_sale_end).diff(dayjs(), "days")} Days
                            </div>
                            <div>From - {dayjs(settings.flash_sale_start).format("DD MMM YYYY HH:mm:ss")}</div>

                            <div>To - {dayjs(settings.flash_sale_end).format("DD MMM YYYY HH:mm:ss")}</div>
                        </div>
                    ) : (
                        <div>No Flash Sale Ongoing</div>
                    )}

                    <Divider>Update Flash Sale Date</Divider>

                    <Form name="flash_sale" onFinish={handleFlashSaleRangeUpdate} layout="vertical" requiredMark={false}>
                        <Form.Item
                            name="range-time-picker"
                            label="Select Date & Time"
                            rules={[
                                {
                                    type: "array",
                                    required: true,
                                    message: "Please select time!",
                                },
                            ]}
                            hasFeedback
                        >
                            <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" showSecond={false} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={flashSaleUpdateLoading} disabled={flashSaleUpdateLoading}>
                                Update Date Range
                            </Button>
                        </Form.Item>
                    </Form>

                    <Divider>Close Flash Sale</Divider>

                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleCloseFlashSale}
                        loading={closeFSLoading}
                        disabled={closeFSLoading}
                    >
                        Close Flash Sale
                    </Button>
                </>
            )}
        </>
    );
};

const MarqueeNews = () => {
    const [modalOpen, setModalOpen] = useState({
        add: false,
        edit: false,
    });

    const [news, setNews] = useState([]);
    const [toEditNews, setToEditNews] = useState("");
    const [loading, setLoading] = useState(false);

    // get all news
    useEffect(() => {
        (async () => {
            let { news } = (await axios.get("/api/public/settings", { withCredentials: true })).data.results;
            if (news) {
                setNews(JSON.parse(news));
            } else {
                setNews([]);
            }
        })();
    }, []);

    const updateNews = async (news) => {
        setLoading(true);
        await axios.patch(
            "/api/admin/settings",
            {
                news: JSON.stringify(news),
            },
            { withCredentials: true }
        );
        setLoading(false);
    };

    return (
        <div className="pt-2 w-100">
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                    setModalOpen({ ...modalOpen, add: true });
                }}
            >
                Add New News
            </Button>

            <Divider orientation="left">Currently Active News Preview</Divider>

            <div
                style={{
                    maxWidth: "1200px",
                    width: "100%",
                    margin: "0 auto",
                }}
            >
                <Marquee>
                    {news &&
                        news.length > 0 &&
                        news?.map((item, index) => (
                            <div key={index} className="px-5">
                                {item}
                            </div>
                        ))}
                </Marquee>
            </div>

            <Divider orientation="left">Currently Active News</Divider>

            <List
                bordered
                loading={loading}
                dataSource={news}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button
                                type="dashed"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setToEditNews(item);
                                    setModalOpen({ ...modalOpen, edit: true });
                                }}
                            >
                                Edit
                            </Button>,
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Are you sure you want to delete this item?",
                                        icon: <WarningOutlined />,
                                        content: "This action cannot be undone.",
                                        centered: true,
                                        async onOk() {
                                            setNews(news.filter((i) => i !== item));
                                            updateNews(news.filter((i) => i !== item));
                                        },
                                        onCancel() {
                                            message.info("No action taken");
                                        },
                                    });
                                }}
                            >
                                Delete
                            </Button>,
                        ]}
                    >
                        {item}
                    </List.Item>
                )}
            />

            <AddNewsModal modalOpen={modalOpen} setModalOpen={setModalOpen} news={news} setNews={setNews} updateNews={updateNews} />

            <EditNewsModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                news={news}
                setNews={setNews}
                toEditNews={toEditNews}
                updateNews={updateNews}
            />
        </div>
    );
};

const AddNewsModal = ({ modalOpen, setModalOpen, news, setNews, updateNews }) => {
    const [add_form] = Form.useForm();
    return (
        <Modal
            title="Add News"
            open={modalOpen.add}
            onCancel={() => {
                setModalOpen({ ...modalOpen, add: false });
            }}
            footer={null}
            destroyOnClose
        >
            <Form
                onFinish={(val) => {
                    setNews([...news, val.news]);
                    updateNews([...news, val.news]);
                    setModalOpen({ ...modalOpen, add: false });
                    add_form.resetFields();
                }}
                layout="vertical"
                form={add_form}
            >
                <Form.Item
                    label="News Title"
                    name="news"
                    rules={[
                        {
                            required: true,
                            message: "Please input news title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const EditNewsModal = ({ modalOpen, setModalOpen, news, setNews, toEditNews, updateNews }) => {
    const [edit_form] = Form.useForm();

    useEffect(() => {
        edit_form.setFieldsValue({
            news: toEditNews,
        });
    }, [edit_form, toEditNews]);

    return (
        <Modal
            title="Edit News"
            open={modalOpen.edit}
            onCancel={() => {
                setModalOpen({ ...modalOpen, edit: false });
            }}
            footer={null}
            destroyOnClose={true}
        >
            <Form
                onFinish={(val) => {
                    setNews(news.map((item) => (item === toEditNews ? val.news : item)));
                    updateNews(news.map((item) => (item === toEditNews ? val.news : item)));
                    setModalOpen({ ...modalOpen, edit: false });
                    edit_form.resetFields();
                }}
                layout="vertical"
                form={edit_form}
            >
                <Form.Item
                    label="News Title"
                    name="news"
                    rules={[
                        {
                            required: true,
                            message: "Please input news title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
