//importing components
import Layout from "../Components/Layout";
import ShopMenu from "../Components/Shop/ShopMenu";
import ViewAllProducts from "../Components/Products/viewAllProducts";

const Shop = () => {
  return (
    <Layout pageName="Shop">
      {/* 📕 ShopMenu component will be on top on all all pages under shop module */}
      <ShopMenu />
      <ViewAllProducts />
    </Layout>
  );
};

export default Shop;
